import { configureStore } from '@reduxjs/toolkit';
import { compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer, { INITIAL_STATE } from './reducers';
import persistConfig from './storage';

const enhancer = compose(thunk);

const persistedReducer = persistReducer(persistConfig, rootReducer());

export const store = configureStore({
  reducer: persistedReducer,
  INITIAL_STATE,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(enhancer) 
});


export const persistor = persistStore(store);

