import React from 'react';
import ReactSelect from 'react-select';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';

class DropDownMenuMainNav extends React.Component {
  constructor(props) {
    super(props);
    const options = this._getFormattedNavItems();
    const selected = this._getSelected(options);
    this.state = {
      selected,
      options
    };
  }

  _getSelected(options) {
    const {location: {pathname}} = this.props;

    return options.find(option => {
      if (pathname === '/' || pathname === '/projects') {
        return option.value === '/projects';
      } else if (pathname.startsWith(option.value)) {
        return option.value;
      }
      return option.value === pathname;
    }) || {};
  }

  _getFormattedNavItems() {
    const {tpAuth: {isTpAdmin}, _t} = this.props;
    return [
      {value: '/projects', label: _t('portal.projects'), showOnDesktop: true},
      {...(isTpAdmin && {value: '/instances', label: _t('portal.instances'), showOnDesktop: true})},
      {value: '/news', label: _t('portal.news'), showOnDesktop: true},
      {value: '/downloads', label: _t('portal.downloads'), showOnDesktop: true},
      {value: '/account', label: _t('portal.editMyAccount'), showOnDesktop: false}
    ].filter(item => item.value);
  }

  componentDidUpdate(prevProps, prevState) {

    const { location: {pathname}} = this.props;
    const {options} = this.state;
    const {selected: {value}} = prevState;
    let currentPath = pathname;
    if(pathname === '/'){
      currentPath = '/projects'
    }
    if (!_.isEqual(prevState.options, options) ||
      (currentPath !== value && value !== undefined && !currentPath.startsWith(value))) {
      return this.setState({selected: this._getSelected(options)});
    }
  }

  onChange(selected) {
    const {value} = selected;
    const { navigate } = this.props;
    if (value) {
      this.setState({selected});
      return navigate(value);
    }
    return null;
  }

  _renderMobileNav() {
    const {selected, options} = this.state;
    return (
      <div className="dropdown is--mobile-nav" data-sel={'mobile-nav'}>
        <ReactSelect
          id={'main-nav'}
          components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
          onChange={e => this.onChange(e)}
          placeholder={selected.label || '...'}
          value={selected}
          isSearchable={false}
          isClearable={false}
          className={'dropdown__select'}
          classNamePrefix={'dropdown__select'}
          options={options}
        />
      </div>
    );
  }

  render() {
    const {options, selected} = this.state;
    return (
      <>
        {selected && selected.label && this._renderMobileNav()}
        <div className="is--desktop-nav" data-sel={'desktop-nav'}>
          {options.map((navItem, i) => {
            const {label, value, showOnDesktop} = navItem;
            if (showOnDesktop) {
              return (
                <NavLink
                  className={ selected.value === value ? 'header__navigation__links__link is--active' : `header__navigation__links__link`}
                  to={value}
                  key={i}
                >
                  {label}
                </NavLink>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  }
}

export default connect()(DropDownMenuMainNav);
