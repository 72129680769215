import React from 'react';

const Links = (props) => {

  const { linkData } = props;

  const renderLinks = () => {

    if (!linkData.props.is_hidden) {
      return (
        <div className={'downloads__body__links__item'}>
          <a 
            href={linkData.props.link} 
            target="_blank" className={'downloads__body__links__item__link'}
          >
          {linkData.props.title.toUpperCase()}
         </a>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    renderLinks()
  );
}

export default Links;