import { createAction, createReducer } from '@reduxjs/toolkit';

export const setWarningClosed = createAction('warningStatus/setWarningClosed');
export const setWarningOpen = createAction('warningStatus/setWarningOpen');

const initialState = { warningClosed: false };

const warningStatusReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setWarningClosed, (state) => {
      state.warningClosed = true;
    })
    .addCase(setWarningOpen, (state) => {
      state.warningClosed = initialState.warningClosed;
    })
});

export default warningStatusReducer;