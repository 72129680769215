const exps = {
  // regexp to generate Password without 1,l,0,i,L,I with minimal special-chars
  regExpPw: /^(?=.*\d)(?=.*[a-h])(?=.*[M-Z])(?=.*[!@#$%&*])[2-9a-hM-Z!@#$%&*]{8,}$/,
  // regexp to vallidate Password
  regExpTestPw: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#\$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~])[0-9a-zA-Z !"#\$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]{8,}$/,
  regExpOrgaId: /^[A-HM-Z2-9]{12}$/,
  ruleRegex: /^(.+?)\[(.+)\]$/,
  numericRegex: /^[0-9]+$/,
  integerRegex: /^\-?[0-9]+$/,
  decimalRegex: /^\-?[0-9]*\.?[0-9]+$/,
  // valid mail-address per W3C HTML5-spec http://www.w3.org/TR/html5/forms.html#valid-e-mail-address
  // emailRegex:/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-_0-9]+\.)+[a-zA-Z]{2,}))$/,
  alphaRegex: /^[a-z]+$/i,
  alphaNumericRegex: /^[a-z0-9-\'_\.,:\(\)&\[\]\/+=\?#@ \xC0-\xFF]+$/i,
  alphaNumericSpecialCharsRegex: /^[a-z0-9-\'_\.,:\(\)&\[\]\/+=\?#@ \xC0-\xFF]+$/i,
  alphaDashRegex: /^[a-z0-9 _-]+$/i,
  alphaDashDotRegex: /^[a-z0-9._-]+$/i,
  alphaDashNoNumRegex: /^[a-z _-]+$/i,
  naturalRegex: /^[0-9]+$/i,
  naturalNoZeroRegex: /^[1-9][0-9]*$/i,
  ipRegex: /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i,
  telNrRegex: /^[0-9 #+()-]+$/i,
  domainRegexp: /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i,
  urlRegex: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
  containSlashes: /^[^\/]+$/,
  
  containsSpecialChars: /[^a-zA-Z0-9]/,
  containsUpper: /[A-Z]/,
  containsLower: /[a-z]/,
  containsDigits: /[0-9]/
};

const _hooks = {
  checked: (field) => {
    return (field.value === true);
  },
  
  not_empty: (field) => {
    let { value } = field;
    if (typeof value === 'string') {
      value = value.trim();
    }
    return (value !== null && value !== '');
  },
  
  matches: (field) => {
    const { comparativeValue } = field;
    
    if (comparativeValue) {
      return field.value === comparativeValue;
    }
    
    return false;
  },
  
  valid_email: (field) => {
    return exps.emailRegex.test(field.value);
  },
  
  valid_emails: (field) => {
    var result = field.value.split(",");
    
    for (var i = 0, resultLength = result.length; i < resultLength; i++) {
      if (!exps.emailRegex.test(result[i])) {
        return false;
      }
    }
    
    return true;
  },
  
  valid_password: (field) => {
    return exps.regExpTestPw.test(field.value);
  },
  
  contain_not_just_num: (field) => {
    return !exps.numericRegex.test(field.value);
  },
  
  min_length: (field, length) => {
    if (!exps.numericRegex.test(length)) {
      return false;
    }
    
    return (field.value.length >= parseInt(length, 10));
  },
  
  max_length: (field, length) => {
    if (!exps.numericRegex.test(length)) {
      return false;
    }
    
    return (field.value.length <= parseInt(length, 10));
  },
  
  exact_length: (field, length) => {
    if (!exps.numericRegex.test(length)) {
      return false;
    }
    
    return (field.value.length === parseInt(length, 10));
  },
  
  greater_than: (field, param) => {
    if (!exps.decimalRegex.test(field.value)) {
      return false;
    }
    
    return (parseFloat(field.value) > parseFloat(param));
  },
  
  less_than: (field, param) => {
    if (!exps.decimalRegex.test(field.value)) {
      return false;
    }
    
    return (parseFloat(field.value) < parseFloat(param));
  },
  
  alpha: (field) => {
    return (exps.alphaRegex.test(field.value));
  },
  
  alpha_numeric: (field) => {
    return (exps.alphaNumericRegex.test(field.value));
  },
  
  alpha_dot_dash: (field) => {
    return (exps.alphaDashDotRegex.test(field.value));
  },
  
  alpha_dash: (field) => {
    return (exps.alphaDashRegex.test(field.value));
  },
  
  alpha_dash_no_num: (field) => {
    return (exps.alphaDashNoNumRegex.test(field.value));
  },
  
  numeric: (field) => {
    return (exps.numericRegex.test(field.value));
  },
  
  integer: (field) => {
    return (exps.integerRegex.test(field.value));
  },
  
  decimal: (field) => {
    return (exps.decimalRegex.test(field.value));
  },
  
  is_natural: (field) => {
    return (exps.naturalRegex.test(field.value));
  },
  
  is_natural_no_zero: (field) => {
    return (exps.naturalNoZeroRegex.test(field.value));
  },
  
  valid_ip: (field) => {
    return (exps.ipRegex.test(field.value));
  },
  
  valid_tel_nr: (field) => {
    return (exps.telNrRegex.test(field.value));
  },
  
  valid_domain: (field) => {
    return (exps.domainRegexp.test(field.value));
  },
  
  valid_url: (field) => {
    return (exps.urlRegex.test(field.value));
  },
  
  is_valid_organame: (field) => {
    return (exps.containSlashes.test(field.value));
  },
  
  pw_digits: (field) => {
    return (exps.containsDigits.test(field.value));
  },
  
  pw_min_length: (field, param) => {
    return field.value.length > param - 1;
  },
  
  has_no_forbidden_chars: (field, param) => {
    const str = `^[a-zA-Z0-9\xC0-\xFF${param.allowed}]*$`;
    const regexp = new RegExp(str);
    return regexp.test(field.value);
  },
  
  pw_special_chars: (field, param) => {
    let str = field.value.split('');
    if (!str.length) {
      return 0;
    }
    let allowedChars = `[${param.allowed}]`;
    allowedChars = new RegExp(allowedChars);
    str = str.filter((char, i) => {
      return allowedChars.test(char);
    });
    return str.length >= param.count && _hooks.has_no_forbidden_chars(field, param);
  },
  
  pw_lower_and_uppercase: (field) => {
    return exps.containsUpper.test(field.value) &&
      exps.containsLower.test(field.value);
  },
  
  default: () => {
    return true;
  }
};

function validateField(field) {
  let errors = [];
  const { rules, value, required } = field;
  
  if (!required && !value) {
    return [];
  }
  /*
   * Run through the rules and execute the validation methods as needed
   */
  
  for (const [method, param] of Object.entries(rules)) {
    if (rules.hasOwnProperty(method) && param && typeof _hooks[method] === 'function') {
      if (!_hooks[method].apply(this, [field, param])) {
        errors.push({ method, param });
      }
    }
  }
  return errors;
}

export default validateField;
