import { Box } from '@mui/material';
import React from 'react';
const LogoHeader = () => {
  return (
    <Box className="tp-portal__header__logo-blue">
      <img src="/public/images/login/logo-top-blue.svg" />
    </Box>
  );
};

export default LogoHeader;
