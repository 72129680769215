import React from 'react';

class SetPasswordFormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWrap: false
    };
  }

  toggleShowText() {
    this.setState({
      showWrap: !this.state.showWrap
    });
  }

  onChange(e) {
    this.props.updateValue(this.props.field.id, e.target.value);
  }

  render() {
    const { field } = this.props;
    return (
      <>
        <label className={'input-field__wrap__label'}>{field.label}</label>
        <input
          id={field.name}
          autoComplete={field.name}
          name={field.name}
          type={this.state.showWrap ? 'text' : 'password'}
          className={'input-field__wrap__input'}
          onChange={e => this.onChange(e)}
          defaultValue={this.props.value} />
        <div
          id={`${field.name}-wrap`}
          className={this.state.showWrap ? 'input-field__wrap__hide-pw' : 'input-field__wrap__show-pw'}
          onClick={() => this.toggleShowText()} />
      </>
    );
  }
}

export default SetPasswordFormInput;
