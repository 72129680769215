import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import QueryString from 'query-string';

export default function SortSelect(props) {
  const { queries, _t } = props;
  const _handleChange = (e) => {
    const sortby = e.target.value;
    let newQueries;
    if (sortby === 'alpha_desc') {
      newQueries = { ...queries, ...{ sortby: 'alpha', page: 1, desc: 1 } };
    } else {
      newQueries = { ...queries, ...{ sortby, page: 1, desc: 0 } };
    }
    const { location: { pathname }, navigate } = props;
    navigate(`${pathname}?${QueryString.stringify(newQueries)}`);
  };
  
  const selectedValue = () => {
    let value = queries.sortby || 'favorite';
    const desc = queries.desc ? parseInt(queries.desc) : 0;
    if (value === 'alpha' && desc) {
      value = `${value}_desc`;
    }
    return value;
  }
  
  return <Select
    sx={{ width: '187px', color: '#939393' }}
    className="sort-select"
    onChange={_handleChange}
    data-sel="sort-select"
    value={selectedValue()}
  >
    <MenuItem value={'favorite'}>{_t('portal.projects.search.byFavorite')}</MenuItem>
    <MenuItem value={'alpha'}>{_t('portal.projects.search.byAlphabet')}</MenuItem>
    <MenuItem value={'alpha_desc'}>{_t('portal.projects.search.byAlphabetDesc')}</MenuItem>
  </Select>;
}
