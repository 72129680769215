import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import CopyrightIcon from '@mui/icons-material/Copyright';
import footerData from './footerData.json';

const Footer = ({ _t }) => {
  const [imprintUrl, setImprintUrl] = useState('');
  const [dataSecurityUrl, setDataSecurityUrl] = useState('');
  const { selectedLanguage, iframe } = useContext(AppContext);
  useEffect(() => {
    const imprintData = Object.entries(footerData.imprint);
    const dataSecurityData = Object.entries(footerData.dataSecurity);
    imprintData.map((data) => {
      if (data[0] === selectedLanguage) {
        setImprintUrl(data[1]);
      }
    });
    dataSecurityData.map((data) => {
      if (data[0] === selectedLanguage) {
        setDataSecurityUrl(data[1]);
      }
    });
  }, [imprintUrl, dataSecurityUrl, selectedLanguage]);

  if (iframe) {
    return null;
  } else {
    return (
      <div className="footer" data-sel="footer">
        <div className="footer__inner">
          <div className="footer__inner__separator" data-sel="footer">
            <hr className={'footer__inner__separator__element'} />
          </div>
          <div className="footer__inner__body">
            <div
              className="footer__inner__body__left"
              data-sel="footer-copyright"
            >
              <CopyrightIcon sx={{ marginRight: '5px' }} />
              <p>
                {_t('portal.gum.footer.copyright', new Date().getFullYear())}
              </p>
            </div>
            <div className="footer__inner__body__right" data-sel="footer-links">
              <a target="_blank" href={`${imprintUrl}`}>
                {_t('portal.footer.imprint.label')}
              </a>
              <span className="footer__inner__body__right__separator">|</span>
              <a target="_blank" href={`${dataSecurityUrl}`}>
                {_t('portal.footer.dataprotection.label')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
