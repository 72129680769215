import React from 'react';

const Richtext = (props) => {

  const { richtext } = props;
   
  return (
    <div 
      className='news-detail__body__desc'
      data-sel={'news-detail-body-description'}
      dangerouslySetInnerHTML={{ __html: richtext }}
    />
  );
};

export default Richtext;