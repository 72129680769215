import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AppContext} from '../../contexts/AppProvider';
import Loading from '../Utils/Loading';
import NewsHeader from './_components/NewsHeader';
import NewsOverview from './_components/NewsOverview';
import {getLocalNewsPostsAndUserData, updateNewsUserData, deleteNewsUserData} from './_models/news';

const News = (props) => {
  const {iframe, selectedLanguage} = useContext(AppContext);
  const [posts, setPosts] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [lastPopupId, setLastPopupId] = useState(0);
  const [baseUrl, setBaseUrl] = useState('/wp/uploads');
  const [menuOpen, setMenuOpen] = useState(true);
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false);
  const {param} = useParams();
  const {profile: {accessToken}} = useContext(AppContext);
  let lang = selectedLanguage === 'de_DE' ? 'de_DE' : 'en_US';

  useEffect(() => {
    if (param === 'delete') {
      deleteNewsUserData(accessToken);
    }

    document.body.classList.add('is--news');

    if (!posts) {
      getLocalPostsAndUserData();
    }

    if (iframe && !iframeIsLoaded && userData) {
      document.body.classList.add('is--in-iframe');
      exchangeDataWithParent();
    }

    if (userData && posts) {
      sendDataToParent();
    }

    if (param !== 'delete' && param !== 'exchange-data' && selectedPost) {
      updateUserDataAndUserService(accessToken);
    }

    handleSelectedNews();

    return () => {
      document.body.classList.remove('is--news'),
        document.body.classList.remove('is--in-iframe')
    };

  }, [param, selectedPost, userData]);


  const updateUserDataAndUserService = () => {
    if (userData) {
      let {unread, read, lastPopUp} = userData;
      const {id} = selectedPost;
      if (unread.includes(id)) {
        unread = unread.filter(function (pId) {
          return pId !== id;
        });
        read.push(id);
        const data = {
          unread,
          read,
          lastPopUp: lastPopupId || lastPopUp
        }
        setUserData(data);
        updateNewsUserData(accessToken, data);
      }
    }
  };

  const handleMobileToggle = () => {
    setMenuOpen(!menuOpen);
  };

  async function getLocalPostsAndUserData() {
    const {posts, data} = await getLocalNewsPostsAndUserData(accessToken);
    if (posts && data) {
      setPosts(posts);
      setUserData(data);
      setSelectedPost(getSelectedNews(posts));
    } else if (posts) {
      setPosts(posts);
      setSelectedPost(getSelectedNews(posts));
    }
  }

  async function exchangeDataWithParent() {
    sendDataToParent({type: 'newsHandShaking'});
    window.addEventListener("message", (e) => {
      const data = e.data;
      const {type, body} = data;
      if (type === 'tpcdeCmsData' && body) {
        const {posts, baseUrl} = body;
        if (posts && baseUrl) {
          setIframeIsLoaded(true);
          setPosts(posts);
          setSelectedPost(getSelectedNews(posts));
          setBaseUrl(`${baseUrl}/wp-content/uploads`);
        }
      } else if (type === 'newsHandShaking') {
        sendDataToParent();
      }
    });
  }

  const sendDataToParent = (data = {}) => {
    const {type} = data;
    window.parent && window.parent.postMessage({
      type: type || 'newsPortalData',
      body: {
        newsHasUnread: userData?.unread?.length,
        newsPopup: getNewsPopup()
      }
    }, "*");
  }

  const getNewsPopup = () => {
    if (posts) {
      const postNewest = posts.find(post => post.newest);
      if (postNewest && postNewest.id !== userData?.lastPopUp && userData?.unread.includes(postNewest.id)) {
        setLastPopupId(postNewest.id);
        return {
          baseUrl,
          post: postNewest
        }
      }
    }
  }

  const getSelectedNews = (currentPosts) => {
    if (currentPosts) {
      return currentPosts.find(post => post.id === parseInt(param)) || currentPosts[0];
    }
  };

  const handleSelectedNews = () => {
    if (param) {
      setSelectedPost(getSelectedNews(posts));
    }
  };

  const markAllAsRead = () => {
    let {unread, read, lastPopUp} = userData;
    if (unread.length) {
      unread.forEach(element => {
        read.push(element);
      });

      const data = {
        unread: [],
        read,
        lastPopUp: lastPopupId || lastPopUp
      }
      setUserData(data);
      updateNewsUserData(accessToken, data);
    }
  };

  if (posts && selectedPost && param !== 'delete' && param !== 'exchange-data') {
    return (
      <div className={'main news'} data-sel={'news'}>
        <NewsHeader {...props} />
        <div className={'news__body'} data-sel={'news-body'}>
          <NewsOverview
            posts={posts}
            userData={userData}
            baseUrl={baseUrl}
            selectedPost={selectedPost}
            lang={lang}
            handleMobileToggle={handleMobileToggle}
            menuOpen={menuOpen}
            markAllAsRead={markAllAsRead}
            {...props}
          />
        </div>
      </div>
    );
  } else if (param === 'delete' || param === 'exchange-data') {
    return <p>Done!!!</p>;
  } else {
    return <Loading/>;
  }
};

export default News;