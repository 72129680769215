import React from "react";
import { Navigate } from "react-router-dom";
import Configs from "../../../config/default";
import { retrieve } from "../../Utils/Retrieve";
import Loading from "./../../Utils/Loading";

class ValidateToken extends React.Component {
  constructor(props) {
    super(props);
    this.children = [];
    const queryParams = new URLSearchParams(props.location.search);
    this.state = {
      isLoading: true,
      token: queryParams.get('token'),
      error: null,
      doRedirect: false
    };
  }
  
  componentDidMount() {
    return this._checkToken();
  }
  
  _checkToken() {
    const { token } = this.state;
    if (token) {
      return this._validateToken();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { location: { search } } = this.props;
    const queryParams = new URLSearchParams(search);
    const newToken = queryParams.get('token');
    const { token } = prevState;
    if (newToken !== token) {
      !this.cancelled && this.setState({
        token: newToken,
        error: null
      }, () => this._checkToken());
    }
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  async _validateToken() {
    const { token } = this.state;
    const { type } = this.props;
    if (!token) {
      return this.setState({
        isLoading: false
      });
    }
    const { routes: { gum } } = Configs;
    const { data, statusText } = await retrieve('', `${gum.public}/check_token`, 'GET', { token });
    if (statusText === 'OK') {
      const { result } = data;
      !this.cancelled && this.setState({
        ...result,
        isLoading: false
      });
    } else {
      // !this.cancelled && this.setState({
      //   ...{email: 'burak.denli@thinkproject.com', type: 'forgot-password', username: 'burak.portal'},
      //   isLoading: false
      // });
      const doRedirect = type === 'account-activation'
        && (data.error.message === 'token expired' || data.error.message === 'invalid token');
      !this.cancelled && this.setState({
        ...data,
        isLoading: false,
        doRedirect
      });
    }
  }
  
  renderRedirect() {
    const { token } = this.state;
    return <Navigate to='/service/resend-token' state={{token}} replace={true}/>;
  }

  render() {
    const { children, ...otherProps } = this.props;
    const { isLoading } = this.state;
    if (this.state.doRedirect) {
      return this.renderRedirect();
    }
    if (isLoading) {
      return <Loading/>;
    }
    if (children) {
      return React.Children.map(children, child =>
        React.cloneElement(child, {
          ...otherProps,
          activation: {
            ...this.state
          }
        }));
    }
    return null;
  }
}

export default ValidateToken;
