// import { store } from '../redux/store';
import axios from 'axios';

export async function retrieve(token, url, method = 'GET', params, headers, signal) {
  let data = null;
  if ((method !== 'GET' && method !== 'DELETE') && params && Object.keys(params).length) {
    data = params;
    params = null;
  }
 
  try {
    const result = await axios({
      url,
      method,
      signal,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...(headers ? headers : {})
      },
      ...(data && { data }),
      ...(params && { params }),
    });
    const { status } = result;
    if (status >= 200 && status < 300) {
      result.statusText = 'OK';
      return result;
      
    }
    return handleErrors(result);
    
  } catch (err) {
    return handleErrors(err.response);
  }
}

function handleErrors(response) {
  return { ...response };
}
