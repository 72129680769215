import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import warning from '../../assets/less/fonts/SVG/warning.svg';
import { Link } from 'react-router-dom';

const NotFound = ({_t}) => {
  const { iframe } = useContext(AppContext);
  return (
    <div className={'main notFound'} data-sel="notFound-component">
      <img src={warning} className={'notFound__img'}/>
      <h3 className={'notFound__title'} data-sel="notFound-title">
        {_t('portal.notFound.title')}
      </h3>
      <p className={'notFound__message'} data-sel="notFound-message">
        {_t('portal.notFound.message')}
      </p>
      {!iframe ?
      <div className={'input-buttons__wrap notFound__button-wrapper'}>
        <Link
          data-sel="back-to-home"
          className={'input-buttons__wrap__button'}
          to={'/projects'}
        >
          {_t('portal.backToHome')} 
        </Link>
      </div>
      :
      null
      }
    </div>
  )
}

export default NotFound;
