import React from "react";
import I18n from './I18n/I18n';
import GlobalError from './Notifications/GlobalError';
import ErrorNotifications from './Notifications/ErrorNotifications';
import WithNavAndLocation from '../WithNavAndLocation';

class AppFrame extends React.Component {
  constructor(props) {
    super(props);
    this.children = [];
    this._tpComponentsDidChange = this._tpComponentsDidChange.bind(this);
  }
  
  _tpComponentsDidChange(moduleName, data) {
    if (data && typeof moduleName === 'string' && moduleName.length) {
      let dataToUpdate = {};
      dataToUpdate[moduleName] = data;
      setTimeout(() => {
        this.children.map((child) => {
          if (child) {
            try {
              child.tpOnComponentsDidChange(dataToUpdate);
            } catch (e) {
              /*ignore errors*/
            }
          }
        });
      }, 0);
    }
  }
  
  _renderChildren() {
    const { children, ...otherProps } = this.props;
  
    if (children) {
      return  React.Children.map(children, child => React.cloneElement(child, {
          ...otherProps,
          tpComponentsDidChange: this._tpComponentsDidChange,
          onRef: ref => (this.children.push(ref))
        }));
    }
    return null;
  }
  
  render() {
    const opts = {
      onRef: ref => this.children.push(ref),
      tpComponentsDidChange: this._tpComponentsDidChange
    };
    return (
      <>
        <I18n {...this.props} {...opts}>
          <ErrorNotifications {...this.props} {...opts}/>
          <GlobalError {...this.props} {...opts}>
            {this._renderChildren()}
          </GlobalError>
        </I18n>
      </>
    );
  }
}

export default WithNavAndLocation(AppFrame);
