import Configs from './../../../../config/default';
import { retrieve } from '../../../Utils/Retrieve';

export async function fetchLanguage(token, locale, defaultLocale) {
  let i18n = {};
  
  let i18nDefault = await retrieve(token, Configs.routes.language(defaultLocale));
  const { statusText: statusTextDefault } = i18nDefault;
  if (statusTextDefault && statusTextDefault !== 'OK') {
    i18nDefault = {};
  } else {
    i18nDefault = i18nDefault.data;
  }
  
 
  i18n = await retrieve(token, Configs.routes.language(locale));
  const { statusText } = i18n;
  if (statusText && statusText !== 'OK') {
    i18n = {};
  } else {
    i18n = i18n.data;
  }
  
  Object.keys(i18n).forEach((key) => (!i18n[key]) && delete i18n[key]);
  return { ...i18nDefault, ...i18n };
}
