import React from 'react';
import SetNewPassword from './_components/SetNewPassword';
import LogoHeader from '../logoHeader';

class ExpiredPassword extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(props.location.search);
    this.state = {
      token: queryParams.get('token'),
      username: queryParams.get('user'),
      state: queryParams.get('state'),
      reason: queryParams.get('error_msg'),
      localeMapper: {
        password_expired: ['portal.passwordExpired'],
        one_time_password: ['portal.oneTimePassword'],
        'Not authorized.': ['restart_auth_process'],
        'password is in history': ['portal.resetPassword.passwordInHistory'],
      },
    };
    console.log(this.state);
  }

  _getMappedTranslation(key) {
    const { _t } = this.props;
    const { localeMapper } = this.state;
    let transl = localeMapper[key] || [key];
    transl = transl.map((msg) => {
      return _t(msg);
    });
    return transl;
  }

  render() {
    const { reason } = this.state;
    const { _t } = this.props;
    return (
      <div className="tp-portal__form">
        <LogoHeader />
        <div className="tp-portal__form-content">
          {reason && <h1>{this._getMappedTranslation(reason)}</h1>}
          <SetNewPassword {...this.props} userProps={this.state} />
        </div>
      </div>
    );
  }
}

export default ExpiredPassword;
