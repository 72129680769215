import React from 'react';

const NothingFound = ({ msg }) => {
  return (
    <div className={'nothing-found'} data-sel={'nothing-found'}>
      <p className={'nothing-found__msg'}>
        {msg}
      </p>
    </div>
  )
}

export default NothingFound;