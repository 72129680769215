import Configs from './../../../config/default';
import {retrieve} from '../../Utils/Retrieve';

const fetchNews = async () => {
  const {routes: {news}} = Configs;
  let {data} = await retrieve(null, news());
  return data;
};

export async function getLocalNewsPostsAndUserData(token) {
  const userData = await getNewsUserData(token);
  const localPosts = await fetchNews();
  const {data, posts} = _filterNewsPostsAndUpdateUserData(localPosts, userData, token);
  return {
    data,
    posts
  }
}

export async function getNewsUserData(token) {
  const params = {
    key: 'portal.news',
  };
  const {routes: {userDataService}} = Configs;
  const result = await retrieve(token, userDataService, 'GET', params);
  if (result && result.data && Object.keys(result.data).length) {
    const {data: {data}} = result;
    return data;
  }
  return {
    unread: [],
    read: [],
    lastPopUp: 0
  };
}

export async function deleteNewsUserData(token) {
  const params = {
    key: 'portal.news',
  };
  const {routes: {userDataService}} = Configs;
  return await retrieve(token, userDataService, 'DELETE', params);
}

export async function updateNewsUserData(token, data) {
  const model = {
    'key': 'portal.news',
    data
  };
  const {routes: {userDataService}} = Configs;
  return retrieve(token, userDataService, 'POST', model);
}

function _getUpdatedUserData(posts, userData) {
  const {maxDurationForUnread} = Configs;
  const currentTimestamp = +new Date();
  const {unread: oUnread, read: oRead, lastPopUp} = userData;
  const newData = {
    unread: [],
    read: [],
    lastPopUp
  };

  oUnread.forEach(oUnreadId => {
    const oUnreadPost = posts.find(post => post.id === oUnreadId);
    if (oUnreadPost && (oUnreadPost.timestamp + maxDurationForUnread) > currentTimestamp) {
      newData.unread.push(oUnreadId);
    } else if (oUnreadPost) {
      newData.read.push(oUnreadId);
    }
  });
  oRead.forEach(oReadId => {
    const oReadPost = posts.find(post => post.id === oReadId);
    if (oReadPost) {
      newData.read.push(oReadId);
    }
  });
  return newData;
}

function _getFilteredPosts(posts) {
  const currentTimestamp = +new Date();
  return posts.filter(post => {
    return post.timestamp <= currentTimestamp;
  });
}

function _filterNewsPostsAndUpdateUserData(posts, userData, token) {
  if (!userData) {
    return {
      posts: _getFilteredPosts(posts)
    }
  }
  const currentTimestamp = +new Date();
  const {maxDurationForUnread} = Configs;
  const newData = _getUpdatedUserData(posts, userData);
  const filteredPosts = _getFilteredPosts(posts);

  filteredPosts.forEach(post => {
    if (!(newData.read.includes(post.id) || newData.unread.includes(post.id))) {
      if ((post.timestamp + maxDurationForUnread) > currentTimestamp) {
        newData.unread.push(post.id);
      } else {
        newData.read.push(post.id);
      }
    }
  });
  updateNewsUserData(token, newData);
  return {
    posts: filteredPosts,
    data: newData
  }
}

