import IdleTimeoutTracker from "@tpcde/thinkproject-idle-timeout-tracker/src/idleTimeoutTracker";
import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchLanguage } from "../components/Appframe/I18n/_models/I18nModel";
import Configs from "./../config/default";

export const AppContext = createContext();

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (error) {
    return true;
  }
};

export const AppProvider = ({ children }) => {
  const { availableLanguages, defaultLocale } = Configs;
  const searchParams = new URLSearchParams(window.location.search);
  const storedLocale = useSelector((state) => state.locale);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState(availableLanguages);
  const [i18n, setI18n] = useState([]);
  const [selenium, setSelenium] = useState(
    Boolean(parseInt(searchParams.get("selenium"), 10))
  );
  const [profile, setProfile] = useState(null);
  const [iframe, setIframe] = useState(isInIframe());
  const [tpAuth, setTpAuth] = useState(null);
  const [servicePage, setServicePage] = useState(false);
  useEffect(() => {
    let storedLang = storedLocale.locale;

    setSelectedLanguage(storedLang);
    const setupTimeoutTracker = (response) => {
      IdleTimeoutTracker({
        warningMessage: response["portal.idle.timeout.warning"],
        warningHeader: response["portal.idle.timeout.warning.header"],
      });
    };
    if (profile || servicePage) {
      setLoading(true);
      fetchLanguage("", storedLang, defaultLocale)
        .then((response) => {
          setI18n(response);
          setupTimeoutTracker(response);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [selectedLanguage, profile]);

  return (
    <AppContext.Provider
      value={{
        isAuthorized,
        setIsAuthorized,
        i18n,
        setI18n,
        selectedLanguage,
        setSelectedLanguage,
        languages,
        setLanguages,
        selenium,
        profile,
        setProfile,
        iframe,
        tpAuth,
        setTpAuth,
        servicePage,
        setServicePage,
      }}
    >
      {!loading && children}
    </AppContext.Provider>
  );
};
