import React from 'react';

const ServicePageName = ({ pageName, className = 'tp-portal__header' }) => {
  return (
    <div className={className}>
      <h1>{pageName}</h1>
    </div>
  );
};
export default ServicePageName;
