import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const Gallery = (props) => {

  const sliderOpts = {
    showArrows: false,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    emulateTouch: true,
    showThumbs: true
  };

  const { gallery, baseUrl } = props;

  const renderCustomThumbs = () => 
    gallery.map((image, i) => {
      return <img key={i} src={baseUrl+image?.sizes?.thumbnail} />;
    });

  const renderGallery = () => {
    if (gallery.length > 1) {
      return (
        <Carousel {...sliderOpts} 
          className='news-detail__body__gallery' 
          data-sel={'news-detail-body-carousel'} 
          renderThumbs={renderCustomThumbs}
        >
          {gallery.map((image, i) => {
            return <img className={'news-detail__body__gallery__img'} 
              key={i} 
              src={baseUrl+image?.sizes?.large}
            />;
          })}
        </Carousel>
      );
    } else if (gallery.length) {
      return (
        <div className='news-detail__body__gallery' data-sel={'news-detail-body-gallery'}>
          <img 
            className={'news-detail__body__gallery__img'} 
            src={baseUrl+gallery[0].sizes?.large}
          />
        </div>
      );
    }
    return null;
  };


  return (
    renderGallery()
  );
}

export default Gallery;