import React from 'react';
import Configs from './../../../config/default';
import { retrieve } from './../../Utils/Retrieve';
import Loading from '../../Utils/Loading';
import { Box, Typography } from '@mui/material';
import PageActionButton from '../pageActionButton';
import LogoHeader from '../logoHeader';
import ServicePageName from '../servicePageName';
class ResendToken extends React.Component {
  constructor(props) {
    super(props);
    const {
      location: {
        state: { token },
      },
    } = props;
    this.state = {
      token,
      tokenSendSuccess: 'notYetSent',
      isLoading: false,
    };
  }

  _resendToken = async () => {
    const { token } = this.state;
    const {
      routes: { gum },
    } = Configs;
    this.setState({
      isLoading: true,
    });
    const { statusText } = await retrieve(
      '',
      `${gum.public}/resend_token`,
      'POST',
      { token }
    );
    if (statusText === 'OK') {
      this.setState({
        tokenSendSuccess: 'success',
        isLoading: false,
      });
    } else {
      this.setState({
        tokenSendSuccess: 'failed',
        isLoading: false,
      });
    }
  };

  render() {
    const { _t } = this.props;
    if (this.state.isLoading) {
      return (
        <div className="tp-portal__form">
          <Loading />
        </div>
      );
    }
    return (
      <div className="tp-portal__form">
        <div className="tp-portal__form-content">
          <LogoHeader />
          <ServicePageName
            pageName={_t('portal.accountActivation.resetToken.title')}
          />
          {this._renderContent()}
        </div>
      </div>
    );
  }

  _renderContent() {
    const { tokenSendSuccess } = this.state;
    const { _t } = this.props;
    const renderedDiv = {
      notYetSent: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: 1,
            width: '100%',
            padding: '30px 0',
          }}
        >
          <div className="tp-portal__messages has--errors" data-sel="message">
            <Typography>
              {_t('portal.accountActivation.resetToken.message')}
            </Typography>
          </div>

          <div className={'input-buttons__wrap'}>
            <PageActionButton
              data-sel="send"
              className="input-buttons__wrap__password-reset"
              onActionButtonClick={this._resendToken}
              label={_t('portal.accountActivation.resetToken.button.label')}
            />
          </div>
        </Box>
      ),
      success: (
        <div className="tp-portal__messages is--success" data-sel="success">
          <Typography>
            {_t(
              'portal.accountActivation.resetToken.success.message.paragraph.1'
            )}
            &nbsp;
            {_t(
              'portal.accountActivation.resetToken.success.message.paragraph.2'
            )}
          </Typography>
        </div>
      ),
      failed: (
        <div className="tp-portal__messages has--errors" data-sel="error">
          <Typography>
            {_t('portal.accountActivation.resetToken.failed.paragraph.1')}
            &nbsp;
            {_t('portal.accountActivation.resetToken.failed.paragraph.2')}
          </Typography>
        </div>
      ),
    };
    return renderedDiv[tokenSendSuccess];
  }
}

export default ResendToken;
