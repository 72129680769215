import { createAction, createReducer } from '@reduxjs/toolkit';

export const setBoxLayout = createAction('layout/setBoxLayout');
export const removeBoxLayout = createAction('layout/removeBoxLayout');

const initialState = { boxLayout: true };

const layoutReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBoxLayout, (state) => {
      state.boxLayout = true;
    })
    .addCase(removeBoxLayout, (state) => {
      state.boxLayout = false;
    })
});

export default layoutReducer;