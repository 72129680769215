import React from 'react';
import IconButton from '@mui/material/IconButton';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const LayoutButtons = (props) => {

  return (
    <div className={'layout-buttons'} data-sel={'layout-buttons'}>
      <IconButton 
        className={`layout-buttons__box ${props.layout === true && 'active'}`}
        data-sel={'boxLayout-button'}
        onClick={props.boxLayout}
      >
        <GridViewRoundedIcon sx={{ fontSize: 30 }}/>
      </IconButton>
      <IconButton 
        className={`layout-buttons__list ${props.layout === false && 'active'}`}
        data-sel={'listLayout-button'}
        onClick={props.listLayout}
      >
        <FormatListBulletedIcon sx={{ fontSize: 30 }}/>
      </IconButton>
    </div>
  )
}

export default LayoutButtons;
