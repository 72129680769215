import { Typography } from '@mui/material';
import React from 'react';
import LogoHeader from '../logoHeader';
import PageActionButton from '../pageActionButton';
import ServicePageName from '../servicePageName';
import Loading from './../../Utils/Loading';
import ReqPasswordViaEmail from './_components/ReqPasswordViaEmail';
import SetNewPassword from './_components/SetNewPassword';
import { getErrorMapper } from './errorMapper';

class ActivateAndSetPassword extends React.Component {
  constructor(props) {
    super(props);
    const {
      activation: { token, error },
      type,
    } = props;
    this.state = {
      type,
      token,
      error,
      errorMapper: getErrorMapper(type),
    };
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  componentDidUpdate(prevProps) {
    const {
      activation: { error: prevError, token: prevToken },
    } = prevProps;
    const {
      activation: { token, error },
    } = this.props;
    if (prevError !== error || prevToken !== token) {
      this.setState({ error, token });
    }
  }

  handleRedirect = () => {
    const { type } = this.props;

    const route =
      type === 'account-activation' ? '/' : '/service/forgot-password';
    this.props.navigate(route);
  };

  _renderContent() {
    const { _t, type } = this.props;
    const { token, error, errorMapper } = this.state;
    let serverError = '';
    if (error) {
      const { message, organization } = error;
      serverError = message || '';
      serverError = errorMapper[serverError] || [serverError];
      serverError = serverError.map((msg) => {
        return _t(msg, organization || '');
      });
      // const { currentapp } = Configs;
      return (
        <>
          <div className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return <p key={i}>{msg}</p>;
            })}
          </div>

          <div className={'input-buttons__wrap'}>
            <PageActionButton
              label={_t('ok')}
              className="input-buttons__wrap__password-reset"
              onActionButtonClick={this.handleRedirect}
            />
          </div>
        </>
      );
    } else if (!token && type === 'account-activation') {
      return (
        <Typography className={'tp-portal__messages has--errors'}>
          {_t('portal.accountActivation.invalidToken')}
        </Typography>
      );
    } else if (!token) {
      return <ReqPasswordViaEmail {...this.props} errorMapper={errorMapper} />;
    } else if (token && !error) {
      return <SetNewPassword {...this.props} errorMapper={errorMapper} />;
    }
    return <Loading />;
  }

  render() {
    const { _t, type } = this.props;
    return (
      <div className={'tp-portal__form'}>
        <div className={'tp-portal__form-content'}>
          <LogoHeader />
          <ServicePageName
            pageName={
              type === 'account-activation'
                ? _t('portal.accountActivation.headline')
                : _t('portal.form.resetPassword.headline')
            }
          />
          {this._renderContent()}
        </div>
      </div>
    );
  }
}

export default ActivateAndSetPassword;
