import React from 'react';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowPrevious from '@mui/icons-material/ArrowBackIosNew';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';

const Paginator = ({ options, queries, _t }) => {
  const { totalResults, pageSize } = options;
  const currentPage = queries.page ? parseInt(queries.page) : 1;
  const totalPages = Math.ceil(totalResults / pageSize);

  const _createUrl = (pageNumber) => {
    const newQueries = { ...queries, ...{ page: pageNumber } }
    return `?${QueryString.stringify(newQueries)}`;
  }
  
  const _pagesList = () => {
    const pages = [];
    let projectsCount = 0;
    for (let i = 1; i <= totalPages; i++) {
      const from = projectsCount + 1;
      const to = i === totalPages ? totalResults : projectsCount += pageSize;
      pages.push({
        label: _t('portal.projects.pagination.options', from, to, totalResults),
        value: i,
        from,
        to,
        url: _createUrl(i)
      });
    }
    return pages;
  }
  
  const pageList = _pagesList();
  const currentIndex = currentPage - 1;
  
  return (
    <>
      <FormControl
        sx={{ m: 1, minWidth: 150 }}
        className={'projects__body__pagination__select'}
        data-sel={'pagination-form'}
      >
        <Select
          value={currentPage}
          inputProps={{ 'aria-label': 'Without label' }}
          data-sel={'pagination-select'}
        >
          {pageList.map((page) => {
            return (
              <MenuItem
                value={page.value}
                key={page.value}
                data-sel={`pagination-select-menu-item-${page.value}`}
              >
                <Link to={page.url}>{page.label}</Link>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <div className={'projects__body__pagination__arrows'} data-sel={'pagination-select-arrows'}>
        <Link to={pageList[0].url}>
          <FirstPageIcon
            sx={{ fontSize: 30 }}
            className={`projects__body__pagination__arrows__arrow-first-page ${currentPage === 1 && 'disabled'}`}
            data-sel={'pagination-select-arrows-first-page-icon'}
          />
        </Link>
        <Link to={currentPage > 1 ? pageList[currentIndex - 1].url : ''}>
          <ArrowPrevious
            sx={{ fontSize: 20 }}
            className={`projects__body__pagination__arrows__arrow-previous ${currentPage === 1 && 'disabled'}`}
            data-sel={'pagination-select-arrows-previous-icon'}
          />
        </Link>
        <Link to={currentPage !== totalPages ? pageList[currentIndex + 1].url : ''}>
          <ArrowNext
            sx={{ fontSize: 20 }}
            className={`projects__body__pagination__arrows__arrow-next ${currentPage === totalPages && 'disabled'}`}
            data-sel={'pagination-select-arrows-next-icon'}
          />
        </Link>
        <Link to={pageList[totalPages - 1].url}>
          <LastPageIcon
            sx={{ fontSize: 30 }}
            className={`projects__body__pagination__arrows__arrow-last-page ${currentPage === totalPages && 'disabled'}`}
            data-sel={'pagination-select-arrows-last-page-icon'}
          />
        </Link>
      </div>
    </>
  )
}

export default Paginator;