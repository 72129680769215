import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { AppContext } from '../../../contexts/AppProvider';
import FavButton from './FavButton';
import { getTranslatedName } from '../_utils/TranslatedName';

class TableView extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      headerTitles: [
        { title: 'portal.projects.projectAbbr', className: 'table__head__item__cell__short-name' },
        { title: 'portal.projects.projectName', className: 'table__head__item__cell__name' },
        { title: 'portal.projects.favourites', className: 'table__head__item__cell__fav' }
      ]
    }
  }
  
  componentDidMount() {
    document.body.classList.add('is--list-view');
  }

  componentWillUnmount() {
    document.body.classList.remove('is--list-view');
  }
  
  render() {
    const { allProjects, _t } = this.props;
    const { selectedLanguage } = this.context;
    const headerTitles = Object.entries(this.state.headerTitles);
    const tableContainerStyles = {
      overflowX: 'auto'
    };
    
    return (
      <TableContainer className={`table-container`} sx={tableContainerStyles}>
        <Table className={`table`} aria-label="simple table">
          <TableHead className={`table__head`}>
            <TableRow className={`table__head__item`}>
              {headerTitles.map((title, i) =>
                <TableCell className={title[1].className} key={i}>
                  {_t(`${title[1].title}`)}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={`table__body`}>
            {allProjects.map((project, i) => (
              <TableRow
                className={`table__body__item`}
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-sel={`table-body-item-row-${i}`}
              >
                <TableCell
                  className={`table__body__item__short-name`}
                  scope="row"
                  data-sel={`table-body-item-short-name`}
                >
                  <a href={project.url} className={`table__body__item__short-name__link`}>
                    {project.name.toUpperCase() || ''}
                  </a>
                </TableCell>
                <TableCell
                  className={`table__body__item__name`}
                  scope="row"
                  data-sel={`table-body-item-name`}
                >
                  <Tooltip
                    title={getTranslatedName(project.names_translated, project.name, selectedLanguage)}
                    placement="top"
                  >
                    <a href={project.url} className={`table__body__item__name__link`}>
                      {getTranslatedName(project.names_translated, project.name, selectedLanguage)}
                    </a>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={`table__body__item__fav`}
                  scope="row"
                  align="right"
                  data-sel={`table-body-item-fav`}
                >
                  <FavButton 
                    project={project} 
                    {...this.props}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default TableView;