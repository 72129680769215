import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import QueryString from 'query-string';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

let timer = 0;

const useEffectAfterFirstInit = (func, props) => {
  const { useEffect, useRef } = React;
  const firstInit = useRef(false);
  useEffect(() => {
    if (firstInit.current) {
      func();
    } else {
      firstInit.current = true;
    }
  }, props);
};

export default function SearchField(props) {
  const { queries, _t } = props;
  const [value, setValue] = useState(queries.q || '');
  
  const _handleChange = (reset) => (e) => {
    const q = reset ? '' : e.target.value;
    setValue(q);
  };
  
  useEffectAfterFirstInit(() => {
    const doSearch = value.length === 0 || value.length >= 1;
    clearTimeout(timer);
    timer = setTimeout(() => {
      const newQueries = { ...queries, ...{ q: value } };
      const { location: { pathname }, navigate } = props;
      if (!value) {
        delete newQueries.q;
      }
      if (doSearch) {
        delete newQueries.page;
        navigate(`${pathname}?${QueryString.stringify(newQueries)}`);
      }
      
    }, 1000);
  }, [value]);
  
  return (
    <div className={'search'}>
      <FormControl
        sx={{ m: 1, width: '260px', margin: 0 }}
        variant="outlined"
        data-sel="search"
        className="search-field"
      >
        <InputAdornment position="start" className="search-field__search-icon" data-sel="search-icon">
          <SearchIcon data-sel="search-icon"/>
        </InputAdornment>
        <InputLabel htmlFor="outlined-adornment-password" data-sel="search-label" className="search-field__label">
          {_t('portal.projects.searchField')}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={'text'}
          value={value || ''}
          className="search-field__input-field"
          data-sel="search-input"
          onChange={_handleChange()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                data-selenium="search-cancel"
                className={`search-cancel-icon ${!value && 'is--hidden'}`}
                onClick={_handleChange('reset')}>
                <CancelIcon data-sel="cancel-icon"/>
              </IconButton>
            </InputAdornment>
          }
          label={_t('portal.projects.searchField')}
        />
      </FormControl>
    </div>
  );
}