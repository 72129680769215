import React, {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import DetailView from './DetailView';
import MenuIcon from '@mui/icons-material/Menu';

const NewsOverview = (props) => {
  const {posts, lang, handleMobileToggle, menuOpen, selectedPost, _t, userData, markAllAsRead} = props;
  const activeItem = useRef(null);
  const overview = useRef(null);

  const isVisible = (ele, container) => {
    if (ele && container) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;
      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      return (
        (eleTop >= containerTop && eleBottom <= containerBottom) ||
        (eleTop < containerBottom && containerBottom < eleBottom)
      );
    }
    return false;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isVisible(activeItem.current, overview.current)) {
        activeItem.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
      }
    });
    return () => clearTimeout(timer);
  });

  if (posts) {
    const {id: selectedId} = selectedPost;
    return (
      <>
        <MenuIcon
          className={'menu-icon'}
          data-sel={'news-menu-icon'}
          onClick={e => {
            e.preventDefault();
            handleMobileToggle();
          }}
        />
        <div
          className={`news-overview ${!menuOpen ? 'menu-open' : ''}`}
          data-sel={'news-overview'}
        >
          <div className={'news-overview__header'} data-sel={'news-overview-header'}>
            <div
              className={'news-overview__header__title'}
              data-sel={'news-overview-header-title'}
            >
              {_t('portal.news')}
            </div>
            <div
              className={`news-overview__header__mark-as-read ${userData && !userData.unread.length && 'disabled'}`}
              data-sel={'news-overview-header-markAsRead'}
              onClick={e => {
                e.preventDefault();
                markAllAsRead();
              }}
            >
              {_t('portal.news.overview.header.markAllAsRead')}
            </div>
          </div>

          <div className={'news-overview__body'} data-sel={'news-overview-body'} ref={overview}>
            <ul className={'news-overview__body__list'} data-sel={'news-overview-body-list'}>
              {
                posts.map((post, i) => {
                  const {id, date} = post;
                  const {props: {box}} = post[lang];
                  const isActiveClass = selectedId === id ? 'is--active' : '';
                  const isUnreadClass = userData && !userData.read.includes(id) ? 'is--unread' : '';

                  return (
                    <li
                      key={i}
                      className={`news-overview__body__list__item ${isActiveClass} ${isUnreadClass}`}
                      data-sel={'news-overview-body-list-item'}
                      ref={selectedId === id ? activeItem : null}
                    >
                      <Link
                        className={'news-overview__body__list__item__link'}
                        data-sel={'news-overview-body-list-item-link'}
                        to={`/news/${id}`}
                        onClick={handleMobileToggle}
                      >
                        <div
                          className={'news-overview__body__list__item__link__date'}
                          data-sel={'news-overview-body-list-item-link-date'}
                        >
                          {date}
                        </div>
                        <h2
                          className={'news-overview__body__list__item__link__title'}
                          data-sel={'news-overview-body-list-item-link-title'}
                        >
                          {box._title}
                        </h2>
                        <p
                          className={'news-overview__body__list__item__link__subtitle'}
                          data-sel={'news-overview-body-list-item-link-subtitle'}
                        >
                          {box._subtitle}
                        </p>
                      </Link>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
        <DetailView {...props} />
      </>
    );
  }
};

export default NewsOverview;