import { Button, useTheme } from '@mui/material';
import React, { useCallback } from 'react';

const PageActionButton = ({
  label,
  className = 'input-buttons__wrap',
  isDisabled = false,
  onActionButtonClick,
}) => {
  const theme = useTheme();
  const handleClick = useCallback(
    (e) => onActionButtonClick(e),
    [onActionButtonClick]
  );
  return (
    <Button
      variant="contained"
      data-sel="back-to-portal"
      className={className}
      color="success"
      disabled={isDisabled}
      onClick={handleClick}
      sx={{ marginTop: '12px' }}
    >
      {label}
    </Button>
  );
};

export default PageActionButton;
