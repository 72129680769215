import React from 'react';
import SetPasswordForm from '../../General/Form/SetPasswordForm';
import { retrieve } from './../../Utils/Retrieve';
import Configs from './../../../config/default';
import Loading from '../../Utils/Loading';
import { savePasswordValues } from '../../../redux/reducers/passwordSecurityReducer';
import { connect } from 'react-redux';
import { getFieldValueById } from '../../Utils/Fields';
import ErrorIcon from '@mui/icons-material/Error';

class EditPassword extends React.Component {
  constructor(props) {
    super(props);
    const { _t } = this.props;
    const errorMapper = {
      'Incomplete or not valid payload': ['portal.IncompleteOrNotValidPayload'],
      'too many requests for this user': ['portal.tooManyRequests'],
      'oldpassword invalid': ['portal.account.changePassword.oldpasswordInvalid'],
      //'required': [],
      //'not empty': [],
      'password is in history': ['portal.account.changePassword.changePassword.passwordIsInHistory']
    };
    this.state = {
      fields: [
        {
          id: 'currentPassword',
          label: _t('portal.account.changePassword.changePassword.currentPassword'),
          name: 'currentPassword',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: this.props.passwordValues['currentPassword'] || ''
        },
        {
          id: 'newPassword',
          label: _t('newPassword'),
          name: 'password',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: this.props.passwordValues['newPassword'] || ''
        },
        {
          id: 'confirmPassword',
          label: _t('confirmPassword'),
          name: 'password1',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: this.props.passwordValues['comparePassword'] || ''
        },
      ],
      pwPolicy: null,
      isLoading: true,
      submitReady: false,
      token: props.profile.accessToken,
      errorMapper,
      updateDataCountAfterSave: 2
    };
  }

  componentDidMount() {
    const { pwPolicy } = this.state;
    if (!pwPolicy) {
      return this._getPwPolicy();
    }
  }

  async _getPwPolicy() {
    const { routes: { gum } } = Configs;
    const { username } = this.props.profile;
    
    const { data, statusText } = await retrieve('', `${gum.public}/pwpolicy/${username}`, 'GET');
    if (statusText === 'OK') {
      let {
        result: {
          pw_digits,
          pw_lower_and_uppercase,
          pw_min_length,
          pw_special_chars_allowed,
          pw_special_chars_count,
        }
      } = data;
      this.props.savePasswordValues({});
      !this.cancelled && this.setState({
        pwPolicy: {
          ...(!!pw_digits && { pw_digits: !!pw_digits }),
          ...(!!pw_lower_and_uppercase && { pw_lower_and_uppercase: !!pw_lower_and_uppercase }),
          pw_min_length,
          pw_special_chars: {
            allowed: pw_special_chars_allowed,
            count: pw_special_chars_count
          },
        },
        isLoading: false
      });
    } else {
      !this.cancelled && this.setState({
        pwPolicy: {},
        isLoading: false
      });
    }
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  updateData(data) {
    const { fields, errors } = data;
    const { updateDataCountAfterSave } = this.state;
    const currentPassword = getFieldValueById(fields, 'currentPassword');
    const newPassword = getFieldValueById(fields, 'newPassword');
    const confirmPassword = getFieldValueById(fields, 'confirmPassword');
    const submitReady = currentPassword && newPassword === confirmPassword && !errors.length;

    this.setState({
      fields,
      submitReady,
      updateDataCountAfterSave: updateDataCountAfterSave + 1
    });
  }

  async _onSubmit() {
    if(this.state.submitReady) {
      const { _t } = this.props;
      const { token, fields } = this.state;
      this.setState({ submitReady: false, isLoading: true });

      let serverError = '';
      const oldpassword = getFieldValueById(fields, 'currentPassword');
      const password = getFieldValueById(fields, 'newPassword');
      const { routes: { gum } } = Configs;
      const { statusText, data: { error } } = await retrieve(token, `${gum.general}/self/changepassword`, 'POST', { oldpassword, password });
      if (statusText === 'OK') {
        this.props.savePasswordValues({});
        const fieldsWithoutValues = fields.map(f => {
          f.value = '';
          return f;
        });
        this.setState({
          serverError,
          saveSuccess: true,
          isLoading: false,
          fields: fieldsWithoutValues,
          updateDataCountAfterSave: 0
        });
      } else {
        const { fields } = this.state;
        const values = {};
        fields.forEach(field => values[field.id] = field.value);
        this.props.savePasswordValues(values);
        const { message, organization } = error;
        serverError = message || '';
        serverError = this.state.errorMapper[serverError] || [serverError];
        serverError = serverError.map((msg) => {
          return _t(msg, organization || '');
        });
        
        return this.setState({
          serverError,
          saveSuccess: false,
          isLoading: false,
          submitDisabled: false
        });
      }
    }
  }

  _onCancel() {
    this.props.savePasswordValues({});
    this.props.onClose(this.props.fieldIndex);
  }

  _renderSuccess() {
    const { saveSuccess } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      return (
        <>
          <div data-sel="pw-success" className={`tp-portal__messages ${isSuccess}`}>
            <p>{ _t('portal.resetPassword.changedSuccess') }</p>
          </div>
        </>
      );
    }
    return null;
  }
  
  _renderError() {
    const { serverError } = this.state;
    if (serverError) {
      return (
        <>
          <div data-sel="pw-errors" className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return <p key={i}>{msg}</p>;
            })}
          </div>
        </>
      );
    }
    return null;
  }

  render() {
    const { _t, profile, passwordValues, passwordInfo, keyIndex, openEdit } = this.props;
    const { isLoading, token, saveSuccess, pwPolicy, fields, submitReady, updateDataCountAfterSave } = this.state;
    const { editable, editMode, label, value } = passwordInfo[0];
    if (isLoading) {
      return <Loading />;
    }
    const userProps = {
      username: profile.username,
      token: token,
    };
    return (
      <div className={`profile__items__item ${editable ? 'with--arrow' : ''}`} key={keyIndex}>
        <div className={'profile__items__item__key'} title={label}>{label}</div>
        {editable && editMode ?
          <div data-sel="pw" className={'profile__items__item__value'}>
            <div className="add--m-b-20">
              <h3>{_t('portal.account.changePassword.title')}</h3>
            </div>
            {this._renderSuccess()}
            {this._renderError()}
            <SetPasswordForm
              type={'set'}
              {...this.props}
              updateData={this.updateData.bind(this)}
              userProps={userProps}
              pwPolicy={pwPolicy || {}}
              fields={fields}
              values={passwordValues} />
            <div className="input-buttons__wrap">
              <p className="input-buttons__wrap__info-text">{_t('portal.form.required_fields')}</p>
              {saveSuccess && updateDataCountAfterSave < 2 ? (
                //shows again cancel button when fields edited after saveSuccess
                <button
                  data-sel="pw-ok"
                  className={'input-buttons__wrap__button'}
                  onClick={this._onCancel.bind(this)}
                >{_t('portal.account.passwordAndSecurity.editEmailAndEditPassword.saveSuccess')}</button>
              ) : (
                <button data-sel="pw-cancel"
                  className={'input-buttons__wrap__button is--grey'}
                  onClick={this._onCancel.bind(this)}
                >{ _t('cancel') }</button>)
              }
              <button
                data-sel="pw-submit"
                className={`input-buttons__wrap__button ${ submitReady ? '' : 'is--disabled' }`}
                onClick={this._onSubmit.bind(this)}
              >{ _t('save') }
              </button>
            </div>
          </div>
          :
          <div className={`profile__items__item__value ${editable ? '' : 'is--disabled'}`}>
            {value}
            {!editable ?
              <div className={`profile__items__item__value__notification`}>
                <ErrorIcon 
                  sx={{
                    marginRight: '5px',
                    fontSize: '15px', 
                    verticalAlign: 'middle', 
                    color: '#B00020', 
                    backgroundColor: 'white',
                    borderRadius: '20px'
                  }}
                />
                <small className={`profile__items__item__value__notification__message`}>
                  {_t('portal.account.passwordAndSecurity.editPassword.rsaWarning')}
                </small> 
              </div>
              :
              null
            }    
          </div>
        }
        {editable && !editMode ?
          <div className="profile__items__item__arrow" onClick={(e) => {e.preventDefault(); openEdit(keyIndex);}}/> 
          : 
          null
        }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    savePasswordValues: (...args) => dispatch(savePasswordValues(...args))
  };
}

function mapStateToProps(state) {
  return {
    passwordValues: state.passwordSecurity.passwordValues
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
