import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import { retrieve } from '../Utils/Retrieve';
import Files from './Files';
import Links from './Links';

const Downloads = (props) => {
  
  const { config: { defaultLocale }} = props;
  const { selectedLanguage, iframe } = useContext(AppContext);
  const [ filesData, setFilesData ] = useState(null);
  const [ linksData, setLinksData ] = useState(null);
  const [ baseUrl, setBaseUrl ] = useState('/wp/uploads');
  
  const { _t } = props;
  const lang =  selectedLanguage ? selectedLanguage : defaultLocale;
 
  const fetchFiles = async() => {
    const { config: { routes: { downloads } } } = props;
    let { data } = await retrieve(null, downloads());
    return data;
  };

  const fetchLinks = async() => {
    const { config: { routes: { links }} } = props;
    let { data } = await retrieve(null, links());
    return data;
  };

  const setFilesAndLinks = async() => {
    if (!iframe && !filesData && !linksData) {
      const [files, links] = await Promise.all([fetchFiles(), fetchLinks()]);
      setFilesData(files);
      setLinksData(links);
    }
    sendDataToParent();
  }

  useEffect(() => {
    if (iframe) {
      exchangeDataWithParent();
    } else {
      setFilesAndLinks();
    }
   
    return () => {
      setFilesData(null);
      setLinksData(null);
      setBaseUrl('/wp/uploads');
    };
  },[iframe]);

  function exchangeDataWithParent() {
    sendDataToParent({type: 'newsHandShaking'});
    window.addEventListener("message", (e) => {
      const data = e.data;
      const {type, body} = data;
      if (type === 'tpcdeCmsData' && body) {
        const {downloads, links, baseUrl} = body;
        if (downloads && links && baseUrl) {
          setFilesData(downloads);
          setLinksData(links);
          setBaseUrl(`${baseUrl}/wp-content/uploads`);
        }
      } else if (type === 'newsHandShaking' && body) {
        sendDataToParent();
      }
    });
  }

  const sendDataToParent = (data = {}) => {
    const { type } = data;
    window.parent && window.parent.postMessage({
      type: type || 'downloadsPortalData'
    }, "*");
  }
 
  return (
    <div className="main downloads" data-sel="downloads">
      <div className={'page__header'}>
        <div className={'page__header__items'}>
          <div className={'page__header__items__left'}>
            <h2 className={'page__header__items__left__title'}
              data-sel={'downloads-title'}>{_t('portal.downloads')}
            </h2>
          </div>
        </div>
        <hr className={'page__header__separator'}/>
      </div>
      <div className={'downloads__body'} data-sel={'downloads-body'}>
        <div className={'downloads__body__files'} data-sel={'downloads-body-files'}>
          <h3 className={'downloads__body__files__title'}>
            {_t('portal.downloads.helpfulFiles')}
          </h3>
          {filesData && filesData.map((fileData, i) => {
            return <Files {...props} fileData={fileData[lang] ? fileData[lang] : fileData[defaultLocale]} baseUrl={baseUrl} key={i} />
          })}
        </div>
        <div className={'downloads__body__links'} data-sel={'downloads-body-links'}>
          <h3 className={'downloads__body__links__title'}>
            {_t('portal.downloads.helpfulLinks')}
          </h3>
          {linksData && linksData.map((linkData, i) => {
            return <Links {...props} linkData={linkData[lang] ? linkData[lang] : linkData[defaultLocale]} key={i} />
          })}
        </div>
      </div>
    </div>
  );
}

export default Downloads;