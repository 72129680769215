import React, { useRef } from 'react';
import Player from './Player';

const Video = (props) => {

  const playerRef = useRef(null);
  const { video, baseUrl } = props;

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: baseUrl+video.mp4.url,
      type: 'video/mp4'
    }],
    controlBar: {
      'pictureInPictureToggle': false
    }
  };
  
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      videojs.log('player is waiting');
    });
  
    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <div 
      className='news-detail__body__video'
      data-sel={'news-detail-body-video'}
    >
      <div className='news-detail__body__video__inner'>
        <Player options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
    </div>
  );
}

export default Video;