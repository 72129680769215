import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoHeader from '../logoHeader';
import PageActionButton from '../pageActionButton';
import ServicePageName from '../servicePageName';

const Logout = ({ _t }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/', { replace: true });
  };
  return (
    <div className="tp-portal__form" data-sel="logout-form">
      <div className="tp-portal__form-content">
        <LogoHeader />
        <ServicePageName pageName={_t('logout.header')} />

        <Box
          sx={{ display: 'flex', flex: 1, width: '100%', padding: '30px 0' }} //TODO: move style to less file
        >
          <Box sx={{ width: '100%' }} data-sel="message">
            <Typography className="tp-portal__messages is--success">
              {_t('logout.message')}
            </Typography>
          </Box>
        </Box>
        <PageActionButton
          label={_t('logout.backToPortal')}
          onActionButtonClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default Logout;
