import React, { useState, useContext } from 'react';
import { AppContext } from '../../../contexts/AppProvider';
import Configs from '../../../config/default';
import { retrieve } from '../../Utils/Retrieve';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { IconButton } from '@mui/material';

const FavButton = (props) => {

  const [ isLoading, setIsLoading ] = useState(false);
  const { profile } = useContext(AppContext);
  const { allProjects, project } = props;

  const _onClick = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const { accessToken } = profile;
      const url = `${Configs.routes.gum.general}/self/projects/${project.id}/favorite`;
      const method = project.favorite === 1 ? 'DELETE' : 'PUT';
      try {
        retrieve(accessToken, url, method)
        .then((response) => {
          if (response.statusText === 'OK') {
            const updatedProject = allProjects.find(p => p.id === project.id);
            if (method === 'PUT') {
              updatedProject.favorite = 1;
            } else {
              updatedProject.favorite = 0;
            }
            setIsLoading(false);
          } 
        })
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  }
  
  const _renderButton = () => {
    if (project.favorite === 1) {
      return (
        <StarIcon
          className={isLoading ? 'is--transparent' : ''}
          sx={{
            fontSize: '30px'
          }}
        />
      );
    }
    return (
      <StarOutlineIcon
        className={isLoading ? 'is--transparent' : ''}
        sx={{
          fontSize: '30px'
        }}
      />
    )
  }
  
  return (
    <div className={'add-to-favorite'} onClick={(e) => e.stopPropagation()}>
      <IconButton
        className={'fav-button'}
        data-sel={'fav-button'}
        onClick={(e) => _onClick(e)}
        aria-label="add to favorite">
        {_renderButton()}
      </IconButton>
    </div>
  );
}

export default FavButton;