import React from 'react';
import validateField from './../../Utils/FieldValidator';

class Text extends React.Component {
  constructor(props) {
    super(props);
    const { field } = props;
    this.state = {
      ...field,
      id: field.id || field.name,
      errors: []
    };
  }
  
  validateValue() {
    const { updateData } = this.props;
    const errors = validateField(this.state);
    this.setState({ errors }, ()=>{
      updateData(this.state);
    });
  }
  
  onChange(event) {
    const { value } = event.target;
    this.setState({ value }, () => {
      this.validateValue();
    });
  }
  
  _renderErrors() {
    const { _t } = this.props;
    const { errors } = this.state;
    if (errors.length) {
      return (
        <ul className={'input-field__wrap__errors__list'}>
          {errors.map((error, i) => {
            return (
              <li className={'input-field__wrap__errors__list__item'} key={i}>
                {_t(`portal.form.${error.method}`, error.param)}
              </li>
            );
          })}
        </ul>
      )
    }
    return null;
  }
  
  render() {
    const { id, label, name, classes, value, errors, required } = this.state;
    const isRequired = required ? 'is--required' : '';
    const hasErrors = errors.length ? 'has--errors' : '';
    const { _t } = this.props;
    return (
      <div className={`input-field__wrap ${classes || ''} ${isRequired} ${hasErrors}`}>
        <label className={'input-field__wrap__label'} htmlFor={id}>
          {_t(label)}
        </label>
        <input
          id={id}
          value={value || ''}
          name={name}
          className={'input-field__wrap__input'}
          onChange={e => this.onChange(e)}
        />
        <div className={'input-field__wrap__errors'}>
          {this._renderErrors()}
        </div>
      </div>
    );
  }
}

export default Text;
