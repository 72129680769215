import { createAction, createReducer } from '@reduxjs/toolkit';

export const savePasswordValues = createAction('passwordSecurity/savePasswordValues');

const initialState = {
  passwordValues: {}
};

const passwordSecurityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(savePasswordValues, (state, action) => {
      state.passwordValues = action.payload
    })
});

export default passwordSecurityReducer;