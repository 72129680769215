import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../contexts/AppProvider';
import Loading from '../Utils/Loading';
import Profile from './Profile';
import PasswordSecurity from './PasswordSecurity';

const Account = (props) => {
  const { profile } = useContext(AppContext);
  const { _t } = props;
  
  const _renderContent = () => {
    if (!profile) {
      return (
        <div className={'profile__right'}>
          <Loading/>
        </div>
      );
    } else {
      return (
        <div className={'profile__right'}>
          {props.location.pathname === '/account/profile' ?
            <Profile {...props} profile={profile}/>
            :
            <PasswordSecurity {...props} profile={profile}/>
          }
        </div>
      );
    }
  }
  
  return (
    <div className={'main profile'}>
      <div className={'page__header'}>
        <div className={'page__header__items'}>
          <div className={'page__header__items__left'}>
            <h2 className={'page__header__items__left__title'} data-sel={'account-title'}>
              {_t('portal.account.title')}
            </h2>
          </div>
        </div>
        <hr className={'page__header__separator'}/>
      </div>
      <div className={'profile__body'}>
        <div className={'profile__left'}>
          <div className={'profile__tabs'}>
            <NavLink to={'/account/profile'} data-sel={'account-profile-tab'}
              className={({ isActive }) => isActive ? 'profile__tabs__tab is--profile is--active' : `profile__tabs__tab is--profile`}
            >
              {_t('portal.account.profile')}
            </NavLink>
            <NavLink to={'/account/password-and-security'} data-sel={'account-pw-security-tab'}
              className={({ isActive }) => isActive ? 'profile__tabs__tab is--secure is--active' : `profile__tabs__tab is--secure`}
            >
              {_t('portal.account.passwordAndSecurity')}
            </NavLink>
          </div>
        </div>
        {_renderContent()}
      </div>
    </div>
  );
}

export default Account;
