import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppProvider';
import { retrieve } from './Retrieve';
import Loading from './Loading';
import { setWarningClosed } from '../../redux/reducers/warningStatusReducer';

class CheckEmailVerification extends React.Component {
  static contextType = AppContext;
  constructor(props) {
  super(props);
  this.state = {
    email: '',
    isLoading: false
  };
}

  componentDidMount() {
    const { profile } = this.context;
    if (!this.props.warningStatus) {
      if (!profile.mail) {
        this.setState({email: profile.mail}, () => this._showNotification('warning', true));
      } else if (profile.emailVerified !== '1') {
        this.setState({email: profile.mail}, () => this._showNotification('warning'));
      }
    }
  }
  
  _showNotification = (type, mailIsMissing) => {
    const { tpComponentsDidChange } = this.props;
    tpComponentsDidChange('notify', {
      type,
      msg: this._renderNotifications(mailIsMissing ? 'warningMissingMail' : type),
      onClose: () => this._onWarningClose(type),
      msgType: 'html'
    });
  }

  _onWarningClose(type) {
    type === 'warning' && this.props.setWarningClosed();
  }

  async _resendToken() {
    this.setState({
      isLoading: true
    });
    const { email } = this.state;
    const { profile } = this.context;
    const { routes: { gum } } = this.props.config;
    const { accessToken } = profile;
    const { statusText } = await retrieve(accessToken, `${gum.self}/change_email`, 'POST', {
      new_email: email
    });
    if (statusText === 'OK') {
      this.setState({
        isLoading: false
      });
      this._showNotification('success');
    } else {
      this.setState({
        isLoading: false
      });
      this._showNotification('error');
    }
  }

  _renderNotifications(type) {
    const { _t } = this.props;
    const { email } = this.state;
    return (
      <div 
        className={`portal-notifications__inner`}
        data-sel="emailVerification-notification"
      >
        <div data-sel="emailVerification-notification-message">
          { type === 'warning' ? 
            _t('portal.emailVerification.message', email)
            :
          type == 'warningMissingMail' ?
            _t('portal.resetPassword.missingEmailAddress')
            :
          type === 'success' ?
            _t('portal.accountActivation.resetToken.success.message.paragraph.1') + ' ' +
            _t('portal.accountActivation.resetToken.success.message.paragraph.2')
            :
            _t('portal.accountActivation.resetToken.failed.paragraph.1') + ' ' +
            _t('portal.accountActivation.resetToken.failed.paragraph.2')
          }
        </div> 
        { type === 'warning' ?
          <div className={'portal-notifications__inner__link'} 
            data-sel="emailVerification-resend-link"
            onClick={() => this._resendToken()}
          >
            {_t('portal.emailVerification.link')}
          </div>
          : type === 'warningMissingMail' ?
            <div>
              <Link className={'portal-notifications__inner__link'}
                to={'/account/password-and-security'}>
                {_t('portal.emailVerification.emptyEmail.button.label')}
              </Link>
            </div>
          :
          null
        }
      </div>
    )
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="portal-notifications">
          <div className="portal-notifications__loader" data-sel="portal-notifications-loader">
            <Loading />
          </div>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    warningStatus: state.warningStatus.warningClosed
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setWarningClosed: () => dispatch(setWarningClosed())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmailVerification);