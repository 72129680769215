import React from 'react';

const NewsHeader = (props) => {

  const { _t } = props;
  
  return (
    <>
      <div className={'page__header'} data-sel={'news-page-header'}>
        <div className={'page__header__items'}>
          <div className={'page__header__items__left'}>
            <h2 className={'page__header__items__left__title'} data-sel={'news-page-header-title'}>{_t('portal.news')}</h2>
          </div>
        </div>
        <hr className={'page__header__separator'}/>
      </div>
    </>
  )
}

export default NewsHeader;
