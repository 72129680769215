import React from 'react';

class GlobalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.timeOut = false;
  }
  
  componentDidMount() {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => this.setState({ showModal: true }), 500);
  }

  componentWillUnmount() {
    this.timeOut = false;
  }
  
  renderBackToPortalLink() {
    const { _t, configs: { portal } } = this.props;
    if (portal) {
      return (
        <a href={portal} className="btn btn-default">{_t('tpappframe.error.goToPortal')}</a>
      );
    }
    return null;
  }
  
  renderSupportLink() {
    const { _t, configs: { support } } = this.props;
    if (support) {
      return (
        <a href={support} className="btn btn-default">{_t('tpappframe.error.goToSupport')}</a>
      );
    }
    return null;
  }
  
  renderAuthorizationLink(status) {
    const { _t, tpAuth } = this.props;
    if (status === 401) {
      return (
        <a className="btn btn-primary" onClick={() => tpAuth.auth()}>{_t('tpappframe.error.reauthorize')}</a>
      );
    } else {
      return (
        <a className="btn btn-primary" onClick={() => tpAuth.logout()}>{_t('tpappframe.error.logout')}</a>
      );
    }
  }

  render() {
    const { showModal } = this.state;
    //const { _t, projectInfo: { error } } = this.props;
    const { _t } = this.props;
    const error = {};
    if (error && Object.keys(error).length) {
      let { status, requestId } = error;
      status = parseInt(error.status, 10);
      if (!status || status % 500 < 10) {
        status = 5;
      }
      return (
        <div className={`modal fade ${showModal ? 'in' : ''}`} style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-danger">{_t('tpappframe.error.title')}</h3>
              </div>
              <div className="modal-body">
                <p className="text-danger">[{new Date().toISOString()}] [{requestId}] [{error.status || 'undefined'}]
                                           - {_t(`tpappframe.error.${status}`)}</p>
              </div>
              <div className="modal-footer">
                {this.renderSupportLink()}
                {this.renderBackToPortalLink()}
                {this.renderAuthorizationLink(status)}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const { children, ...otherProps } = this.props;
      let childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, { ...otherProps }));
      return (childrenWithProps);
    }
  }
}

export default GlobalError;
