import { Box, Typography } from '@mui/material';
import React from 'react';
import Loading from '../../Utils/Loading';
import LogoHeader from '../logoHeader';
import PageActionButton from '../pageActionButton';
import ServicePageName from '../servicePageName';
import Configs from './../../../config/default';
import { retrieve } from './../../Utils/Retrieve';

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    const {
      activation: { token },
    } = props;
    let {
      activation: { error },
    } = props;
    if (!error && !token) {
      error = {
        message: 'no token provided',
      };
    }
    this.state = {
      error,
      token,
      isSuccess: false,
    };
  }

  async componentDidMount() {
    let error = this.state.error,
      isSuccess = false;
    if (!error) {
      this.setState({
        isLoading: true,
      });
      const { data, statusText } = await retrieve(
        '',
        `${Configs.routes.gum.public}/change_email_via_token`,
        'POST',
        { token: this.state.token }
      );
      if (statusText === 'OK') {
        isSuccess = true;
      } else {
        error = data.error;
        if (!(error && error.length)) {
          error = {
            message: 'unknown error',
          };
        }
      }
    }
    this.setState({
      isLoading: false,
      error,
      isSuccess,
    });
  }

  render() {
    const { _t } = this.props;
    return (
      <div className="tp-portal__form">
        <div className="tp-portal__form-content">
          <LogoHeader />
          <ServicePageName pageName={_t('portal.service.confirmEmail.title')} />
          {this._renderErrorOrSuccess()}
        </div>
      </div>
    );
  }

  handleRedirectToPasswordAndSecurity = () => {
    this.props.navigate('/account/password-and-security');
  };

  _renderErrorOrSuccess() {
    const { _t } = this.props;
    const { error, isLoading, isSuccess } = this.state;
    const translationMapper = {
      'no token provided': ['portal.noTokenProvided'],
      'invalid token': ['portal.changeEmail.invalidToken'],
      success: ['portal.service.confirmEmail.success'],
      'unknown error': ['unknownErrorRetryLater'],
    };
    if (isLoading) {
      return <Loading />;
    }
    if (isSuccess) {
      return (
        <>
          <div className="tp-portal__messages is--success" data-sel="message">
            <Typography>
              {translationMapper['success'].map((m) => _t(m)).join(' ')}
            </Typography>
          </div>
          <Box className="input-buttons__wrap">
            <PageActionButton
              data-sel="ok"
              label={_t('ok')}
              className="input-buttons__wrap__password-reset"
              onActionButtonClick={this.handleRedirectToPasswordAndSecurity}
            />
          </Box>
        </>
      );
    } else {
      if (error) {
        return (
          <div className="tp-portal__messages has--errors" data-sel="message">
            <Typography>
              {translationMapper[error.message]
                ? translationMapper[error.message].map((m) => _t(m)).join(' ')
                : error.message}
            </Typography>
          </div>
        );
      } else {
        return (
          <div className="tp-portal__messages has--errors" data-sel="message">
            <Typography>{_t('unknown error')}</Typography>
          </div>
        );
      }
    }
  }
}

export default ConfirmEmail;
