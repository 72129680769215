import React from 'react';
import pdfImg from '../../assets/less/fonts/SVG/file-pdf.svg';

const Files = (props) => {

  const { fileData, baseUrl } = props;
 
  const renderFiles = () => {

    if (!fileData.props.is_hidden) {
      return (
        <div className={'downloads__body__files__item'}>
          <img src={pdfImg} className={'downloads__body__files__item__img'}/>
            <a 
              href={baseUrl+fileData.props.file.url} 
              target="_blank" className={'downloads__body__files__item__link'}>
              {fileData.props.title.toUpperCase()}
            </a>
            <a 
              href={baseUrl+fileData.props.file.url}
              target="_blank" className={'downloads__body__files__item__arrow'}
            />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    renderFiles()
  );
}

export default Files;