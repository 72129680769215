import React from 'react';
import { AppContext } from '../../../contexts/AppProvider';

class I18n extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this._translate = this._translate.bind(this);
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
 
  _translate() {
    const { i18n, selenium } = this.context;
    const args = Array.prototype.slice.call(arguments);
    const text = args.shift() || '';
    if (selenium) {
      return text;
    }
    let translated = i18n[text];
    
    if (translated && args.length > 0) {
      args.forEach((arg) => {
        arg += '';
        if (arg) {
          translated = translated.replace(/%s/i, arg);
        } else {
          translated = translated.replace(/%s/i, '');
        }
      });
    }
    return translated || text;
  }
  
  
  render() {
    const { i18n } = this.context;
    if (!i18n) {
      return null;
    }
    const { children, ...otherProps } = this.props;
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        ...otherProps,
        _t: this._translate
      }));
  }
}

export default I18n;