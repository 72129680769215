import { combineReducers } from 'redux';
import localeReducer from './reducers/localeReducer';
import passwordSecurityReducer from './reducers/passwordSecurityReducer';
import layoutReducer from './reducers/layoutReducer';
import warningStatusReducer from './reducers/warningStatusReducer';

export const INITIAL_STATE = {};

const rootReducer = () => combineReducers({
  locale: localeReducer,
  passwordSecurity: passwordSecurityReducer,
  layout: layoutReducer,
  warningStatus: warningStatusReducer
});

export default rootReducer;
