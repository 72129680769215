import React from 'react';

const Links = (props) => {

  const renderLinks = () => {

    const { links } = props;

    if (links && links.length) {
      return links.map((link, i) => {
        return (
          <div key={i} className={'news-detail__body__links'} data-sel={'news-detail-body-links'}>
            <a href={link.link} target="_blank" className={'news-detail__body__links__link'}>
              {link.title.toUpperCase()}
            </a>
          </div>
        )
      })
    }
    return null;
  };
   
  return (
    renderLinks()
  );
}

export default Links;