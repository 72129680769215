import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LogoHeader from '../logoHeader';
import PageActionButton from '../pageActionButton';
import ServicePageName from '../servicePageName';

const SessionTimeout = ({ _t }) => {
  const [searchParams] = useSearchParams();
  const [isSatellite, setIsSatellite] = useState(0);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    let newWindow = open(location, '_self');
    newWindow.close();
    return false;
  }, []);
  useEffect(() => {
    if (searchParams.get('isSatellite')) {
      setIsSatellite(1);
    }
  }, []);
  const handleLogout = useCallback(() => {
    navigate('/', { replace: true });
  }, []);
  return (
    <div className="tp-portal__form" data-sel="logout-form">
      <div className="tp-portal__form-content">
        <LogoHeader />
        <ServicePageName pageName={_t('session.logout.header')} />

        <Box
          sx={{ display: 'flex', flex: 1, width: '100%', padding: '30px 0' }}
        >
          <Box sx={{ width: '100%' }} data-sel="message">
            <Typography className="tp-portal__messages is--success">
              {_t('logout.message')}
            </Typography>
          </Box>
        </Box>
        {isSatellite ? (
          <PageActionButton
            data-sel="close-portal"
            onActionButtonClick={handleClose}
            label={_t('session.logout.close')}
          />
        ) : (
          <PageActionButton
            label={_t('session.logout.backToPortal')}
            data-sel="back-to-portal"
            onActionButtonClick={handleLogout}
          />
        )}
      </div>
    </div>
  );
};

export default SessionTimeout;
