import React from 'react';
import pdfImg from '../../../../assets/less/fonts/SVG/file-pdf.svg';

const Attachments = (props) => {

  const renderAttachments = () => {

    const { attachments, baseUrl } = props;

    if (attachments && attachments.length) {
      return attachments.map((attachment, i) => {
        return (
          <div key={i} className={'news-detail__body__attachments'} data-sel={'news-detail-body-attachments'}>
            <img src={pdfImg} className={'news-detail__body__attachments__img'}/>
            <a href={baseUrl+attachment.file.url} target="_blank" className={'news-detail__body__attachments__link'} rel="noreferrer">
              {attachment.file.title.toUpperCase()}
            </a>
            <a href={baseUrl+attachment.file.url} target="_blank" className={'news-detail__body__attachments__arrow'} rel="noreferrer"/>
          </div>
        );
      });
    }
    return null;
  };

  return (
    renderAttachments()
  );
};

export default Attachments;