import React from 'react';
import { AppContext } from '../../../contexts/AppProvider';
import EditPassword from './EditPassword';
import EditEmail from './EditEmail';
import Username from './Username';

class PasswordSecurity extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      fields: null,
      isFederated: false,
      isCicLinked: false
    };
  }

  componentDidMount() {
    const { profile } = this.context;
    const isFederated = profile.isfederated === "1" || profile.isfederated === 1;
    const isCicLinked = profile.cic_linked === "1";
    this.setState({
      isFederated,
      isCicLinked,
      fields: this._getFields(isFederated, isCicLinked)
    });
  }
  
  _getFields(isFederated, isCicLinked) {
    const { _t } = this.props;
    const { profile } = this.context;
    return [
      { key: 'username', label: _t('userName'), value: profile.username, editable: false },
      { key: 'mail', label: _t('email'), value: profile.mail, editable: !(isFederated || isCicLinked), editMode: false },
      { key: 'password', label: _t('password'), value: '********', editable: !(profile.rsa === "1" || profile.rsa === 1), editMode: false }
    ];
  }

  _toggleEdit = (index) => {
    let fields = [...this.state.fields];
    fields[index].editMode = !fields[index].editMode;
    this.setState({
      fields
    });
  }

  render() {
    const { _t } = this.props;
    const { fields, isFederated, isCicLinked } = this.state;
   
    return (
      <>
        <div className={'profile__intro'}>
          <div className={'profile__intro__title'}>
            <h2 data-sel={'account-pw-sec-title'}>{_t('portal.account.passwordAndSecurity')}</h2>
          </div>
          <div className={'profile__intro__text'}>
            <h3 data-sel={'account-pw-sec-intro'}>{_t('portal.account.passwordAndSecurity.intro')}</h3>
          </div>
        </div>
        <div className={'profile__items'}>
          {fields &&
            <>
              <Username 
                {...this.props} 
                userInfo={fields.filter(field => field.key === 'username')} 
                keyIndex={fields.map(field => field.key).indexOf('username')}
              />
              <EditEmail 
                {...this.props} 
                onClose={(e) => this._toggleEdit(e)} 
                fieldIndex={fields.map(field => field.key).indexOf('mail')} 
                _t={_t} 
                emailInfo={fields.filter(field => field.key === 'mail')} 
                keyIndex={fields.map(field => field.key).indexOf('mail')}
                openEdit={this._toggleEdit}
                isFederated={isFederated}
                isCicLinked={isCicLinked}
              />
              <EditPassword 
                {...this.props} 
                onClose={(e) => this._toggleEdit(e)} 
                fieldIndex={fields.map(field => field.key).indexOf('password')} 
                _t={_t} 
                passwordInfo={fields.filter(field => field.key === 'password')} 
                keyIndex={fields.map(field => field.key).indexOf('password')}
                openEdit={this._toggleEdit}
              /> 
            </>
          }
        </div>
      </>
    );
  }
}

export default PasswordSecurity;