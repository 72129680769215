import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { AppLayout } from "./AppLayout";
import {} from "./assets/less/styles.less";
import Theme from "./assets/theme";
import Account from "./components/Account";
import PasswordSecurity from "./components/Account/PasswordSecurity";
import Profile from "./components/Account/Profile";
import AppFrame from "./components/Appframe/AppFrame";
import Authorize from "./components/Authorize";
import Downloads from "./components/Downloads";
import Footer from "./components/Footer";
import ValidateToken from "./components/General/ValidateToken";
import Header from "./components/Header";
import Instances from "./components/Instances";
import News from "./components/News";
import NewsOverview from "./components/News/_components/NewsOverview";
import NotFound from "./components/NotFound";
import Projects from "./components/Projects";
import Service from "./components/Service";
import ExpiredPassword from "./components/Service/ExpiredPassword";
import Logout from "./components/Service/Logout";
import ResendToken from "./components/Service/ResendToken";
import SessionTimeout from "./components/Service/SessionTimeout";
import Signup from "./components/Service/Signup";
import CheckEmailVerification from "./components/Utils/CheckEmailVerification";
import Configs from "./config/default";
import { AppProvider } from "./contexts/AppProvider";
import { persistor } from "./redux/store";

const App = (props) => {
  const mainWrapper = (component) => {
    return (
      <AppFrame>
        <Authorize {...props} config={Configs}>
          <CheckEmailVerification />
          <Header />
          {component}
          <Footer />
        </Authorize>
      </AppFrame>
    );
  };

  const serviceWrapper = (component) => {
    return <AppFrame>{component}</AppFrame>;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<AppLayout />}>
          <Route
            index
            element={mainWrapper(<Projects />)}
            errorElement={mainWrapper(<NotFound />)}
          />
          <Route path="projects" element={mainWrapper(<Projects />)} />
          <Route path="account" element={mainWrapper(<Account />)}>
            <Route path="profile" element={<Profile />} />
            <Route
              path="password-and-security"
              element={<PasswordSecurity />}
            />
          </Route>
          <Route path="news" element={mainWrapper(<News />)}>
            <Route path=":param" element={<NewsOverview />} />
          </Route>
          <Route path="instances" element={mainWrapper(<Instances />)} />
          <Route path="downloads" element={mainWrapper(<Downloads />)} />
          <Route path="*" element={mainWrapper(<NotFound />)} />
          <Route path="service" element={serviceWrapper(<Service />)}>
            <Route path="signup" element={<Signup />} />
            <Route path="resend-token" element={<ResendToken />} />
            <Route
              path="forgot-password"
              element={<ValidateToken type={"forgot-password"} />}
            />
            <Route path="password-expired" element={<ExpiredPassword />} />
            <Route
              path="account-activation"
              element={<ValidateToken type={"account-activation"} />}
            />
            <Route
              path="confirm-email"
              element={<ValidateToken type={"confirm-email"} />}
            />
            <Route path="logout" element={<Logout />} />
            <Route path="session-timeout" element={<SessionTimeout />} />
          </Route>
        </Route>
      </>
    )
  );

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <AppProvider>
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
