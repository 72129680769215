import React, { useContext, useEffect } from "react";
import Auth from "@tp/thinkproject-auth";
import Loading from "./../Utils/Loading";
import { retrieve } from "./../Utils/Retrieve";
import { AppContext } from "../../contexts/AppProvider";

const Authorize = (props) => {
  const {
    iframe,
    profile,
    setProfile,
    isAuthorized,
    setIsAuthorized,
    tpAuth,
    setTpAuth,
  } = useContext(AppContext);
  const { config } = props;

  let authWithOpts;

  useEffect(() => {
    if (!isAuthorized) {
      _authorizeUser();
    }
  }, [isAuthorized]);

  useEffect(() => {
    _addTimeoutListener();
  }, []);

  const _authorizeUser = () => {
    const { opIframeUrl } = config.sso.auth0Opts;
    const auth0Opts = {
      onSuccess: () => {
        return _getGumProfile();
      },
      ...(iframe ? { opIframeUrl: "" } : { opIframeUrl }),
    };
    authWithOpts = Auth(config.sso.auth0Params, {
      ...config.sso.auth0Opts,
      ...auth0Opts,
    });
    setTpAuth(authWithOpts);
  };

  const _addTimeoutListener = () => {
    window.addEventListener("message", (ev) => {
      let data = "";
      try {
        data = JSON.parse(ev.data);
      } catch (e) {
        return false;
      }
      if (data.action === "timeout") {
        window.top.location = "/service/session-timeout";
      }
    });
  };

  const _getGumProfile = () => {
    if (!profile) {
      return _retrieveGumProfile();
    } else {
      setIsAuthorized(true);
    }
  };

  const _retrieveGumProfile = async () => {
    const authProfile =
      authWithOpts && authWithOpts.isAuthorized() ? authWithOpts.profile() : {};
    const {
      routes: { gum },
    } = config;
    const { accessToken } = authProfile;

    let { data } = await retrieve(accessToken, `${gum.self}/profile`);

    if (data && data.user) {
      let profile = data.user || {};
      profile = { ...profile, accessToken };
      setProfile(profile);
      setIsAuthorized(true);
    }
  };

  const _renderChildren = (match) => {
    const { children, ...otherProps } = props;
    const { admin_status, admin_permission } = profile;
    const isSuperAdmin = admin_status === "super_admin";
    const isTpAdmin = isSuperAdmin || admin_status === "tp_admin";
    const isOrgaAdmin =
      isTpAdmin || (admin_status === "orga_admin" && admin_permission === "rw");
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...otherProps,
        props,
        tpAuth: { ...tpAuth, isSuperAdmin, isTpAdmin, isOrgaAdmin },
      })
    );
  };

  if (!profile && !isAuthorized) {
    return <Loading />;
  }
  return _renderChildren();
};

export default Authorize;
