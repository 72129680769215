import React, { useState, useEffect, useContext } from "react";
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { AppContext } from "../../../contexts/AppProvider";
import { saveLocale } from "../../../redux/reducers/localeReducer";

const DropDownMenuLanguage = (props) => {
  
  const { selectedLanguage, setSelectedLanguage, languages } = useContext(AppContext);
  const [ selected, setSelected ] = useState({ value: selectedLanguage, label: languages[selectedLanguage]});
  const [ options, setOptions ] = useState(null);
  
  useEffect(() => {
    if (!options) {
      setLanguageOptions();
    }
  },[])

  const setLanguageOptions = () => {
    const navItems = [];
    Object.entries(languages).forEach(([value, label]) => {
      navItems.push({ value, label });
    });
    setOptions(navItems);
  }

  const onChange = (selectedDropdown) => {
    const opt = options.find(option => option.value === selectedDropdown.value);
    props.saveLocale(opt.value);
    setSelectedLanguage(opt.value);
    setSelected(prevState => ({
      ...prevState,
      value: opt.value,
      label: opt.label
   }));
  }

 
  return (
    <div className='dropdown' data-sel={'language-dropdown'}>
      <ReactSelect
        id={'language-nav'}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        onChange={e => onChange(e)}
        placeholder={selected?.label}
        value={selected}
        isSearchable={false}
        isClearable={false}
        className={'dropdown__select'}
        classNamePrefix={'dropdown__select'}
        options={options}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    saveLocale: (...args) => dispatch(saveLocale(...args))
  };
}

export default connect(null, mapDispatchToProps)(DropDownMenuLanguage);
