import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppProvider';
import DropDownMenuMainNav from './_components/DropDownMenuMainNav';
import DropDownMenuLanguage from './_components/DropDownMenuLanguage';
import DropDownMenuUser from './_components/DropDownMenuUser';
import Logo from '/public/images/logos/Logo-CDE-Enterprise.svg';

const Header = (props) => {

  const { iframe } = useContext(AppContext);

  if (iframe) {
    return null;
  } else {
    return (
      <>
        <div className='header'>
          <Link to={'/'} className={'header__logo'} data-sel={'logo'}>
            <img src={Logo}/>
          </Link>
          <div className={'header__navigation'}>
            <div className={'header__navigation__links'} data-sel={'main-nav'}>
              <DropDownMenuMainNav {...props}/>
            </div>
            <div className={'header__navigation__menus'}>
              <div className={'header__navigation__menus__language'} data-sel={'language-nav'}>
                <DropDownMenuLanguage {...props}/>
              </div>
              <div className={'header__navigation__menus__user'} data-sel={'user-nav'}>
                <DropDownMenuUser {...props}/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;