import React from 'react';
import NotFound from '../../../NotFound';
import Image from './Image';
import Richtext from './Richtext';
import Gallery from './Gallery';
import Attachments from './Attachments';
import Links from './Links';
import Video from './Video';

export default function DetailView(props) {

  const {lang, selectedPost, selectedPost: {date}} = props;
  const post = selectedPost[lang];

  const _returnContent = () => {
    const {_t} = props;
    if (post) {
      return (
        <div
          className={'news-detail'}
          data-sel={'news-detail'}
        >
          <div className={'news-detail__header'} data-sel={'news-detail-header'}>
            <div className={'news-detail__header__date'} data-sel={'news-detail-header-date'}>
              {date}
            </div>
            <h1 className={'news-detail__header__title'} data-sel={'news-detail-header-title'}>
              {post.props.title}
            </h1>
            <h2 className={'news-detail__header__intro'} data-sel={'news-detail-header-intro'}>
              {post.props.subtitle}
            </h2>
          </div>
          <div className="news-detail__body" data-sel={'news-detail-body'}>
            {post.props.elements.map((element, i) => {
              if (element.layout === 'image') {
                return <Image {...props} image={element.image} key={i}/>;
              }
              if (element.layout === 'richtext') {
                return <Richtext {...props} richtext={element.richtext} key={i}/>;
              }
              if (element.layout === 'gallery' && element.gallery) {
                return <Gallery {...props} gallery={element.gallery} key={i}/>;
              }
              if (element.layout === 'attachments') {
                return <Attachments {...props} attachments={element.content_attachments} key={i}/>;
              }
              if (element.layout === 'links') {
                return <Links {...props} links={element.content_links} key={i}/>;
              }
              if (element.layout === 'video') {
                return <Video {...props} video={element} key={i}/>;
              }
            })}
          </div>
        </div>
      );
    } else {
      return (<NotFound {...props} _t={_t}/>);
    }
  };

  return (
    _returnContent()
  );
}