import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/AppProvider';
import ValidateToken from '../General/ValidateToken';
import ActivateAndSetPassword from './ActivateAndSetPassword';
import ConfirmEmail from './ConfirmEmail';
import ExpiredPassword from './ExpiredPassword';
import Logout from './Logout';
import ResendToken from './ResendToken';
import SessionTimeout from './SessionTimeout';
import Signup from './Signup';

const Service = (props) => {
  const { setServicePage } = useContext(AppContext);

  useEffect(() => {
    setServicePage(true);
    document.body.classList.add('is--service');
    return () => {
      document.body.classList.remove('is--service');
      setServicePage(false);
    };
  });

  return (
    <div className="tp-portal">
      {props.location.pathname === '/service/signup' ? (
        <Signup {...props} />
      ) : props.location.pathname === '/service/resend-token' ? (
        <ResendToken {...props} />
      ) : props.location.pathname === '/service/forgot-password' ? (
        <ValidateToken {...props} type={'forgot-password'}>
          <ActivateAndSetPassword />
        </ValidateToken>
      ) : props.location.pathname === '/service/password-expired' ? (
        <ExpiredPassword {...props} />
      ) : props.location.pathname === '/service/account-activation' ? (
        <ValidateToken {...props} type={'account-activation'}>
          <ActivateAndSetPassword />
        </ValidateToken>
      ) : props.location.pathname === '/service/confirm-email' ? (
        <ValidateToken {...props} type={'confirm-email'}>
          <ConfirmEmail />
        </ValidateToken>
      ) : props.location.pathname === '/service/logout' ? (
        <Logout {...props} />
      ) : props.location.pathname === '/service/session-timeout' ? (
        <SessionTimeout {...props} />
      ) : null}
    </div>
  );
};

export default Service;
