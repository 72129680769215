import React from 'react';
import { AppContext } from '../../../contexts/AppProvider';
import LogoHeader from '../logoHeader';
import PageActionButton from '../pageActionButton';
import ServicePageName from '../servicePageName';
import Loading from './../../Utils/Loading';
import Select from './../Inputs/Select';
import Text from './../Inputs/Text';
import { getSpecModel, saveUser } from './_models/signup';
import { Box, Typography } from '@mui/material';

class Signup extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      fields: null,
      submitDisabled: true,
      serverError: '',
      isLoading: false,
      saveSuccess: false,
    };
    this._updateData = this._updateData.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  componentDidMount() {
    if (!this.state.fields) {
      return this._getSpecModel();
    }
  }

  _updateData(data) {
    const { name, value, errors } = data;
    this.setState(
      (prevState) => ({
        fields: prevState.fields.map((field) =>
          field.name === name ? { ...field, value, errors } : field
        ),
      }),
      () => {
        this._checkRequiredFields();
      }
    );
  }

  _checkRequiredFields() {
    const { fields } = this.state;
    const fieldsWithErrors = fields.filter((field) => {
      return (
        (field.errors && field.errors.length) ||
        (field.required && !field.value)
      );
    });
    if (!fieldsWithErrors.length) {
      return this.setState({ submitDisabled: false });
    }
    return this.setState({ submitDisabled: true });
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { fields, submitDisabled } = this.state;
    if (!submitDisabled) {
      this.setState({ submitDisabled: true, isLoading: true });
      const savedUser = await saveUser(fields);
      const {
        data: { success, id, error },
      } = savedUser;
      if (success && id) {
        this.setState({
          serverError: '',
          saveSuccess: true,
          isLoading: false,
        });
      } else {
        // TODO: mapper to map server errors to translated key-values in lokalise.
        const errMsg = error.message || '';
        return this.setState({
          serverError: errMsg,
          isLoading: false,
          submitDisabled: false,
        });
      }
    }
  }

  async _getSpecModel() {
    const { selectedLanguage, languages } = this.context;
    const specModel = await getSpecModel(
      this.props,
      selectedLanguage,
      languages
    );
    if (specModel) {
      !this.cancelled &&
        this.setState({
          ...specModel,
        });
    }
  }

  _renderLoading() {
    const { isLoading } = this.state;
    return isLoading ? <Loading /> : null;
  }

  _renderSuccess() {
    const { fields, saveSuccess } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      const emailField = fields.find((field) => {
        return field.name === 'mail';
      });
      return (
        <div className={`tp-portal__messages ${isSuccess}`}>
          {_t('portal.form.signup.success', emailField.value)}
        </div>
      );
    }
    return null;
  }

  _renderError() {
    const { serverError } = this.state;
    const { _t } = this.props;
    const hasServerError = serverError ? 'has--errors' : '';
    if (serverError) {
      return (
        <div className={`tp-portal__messages ${hasServerError}`}>
          {serverError ? _t(`portal.form.${serverError}`) : ''}
        </div>
      );
    }
    return null;
  }

  _renderContent() {
    const { fields, saveSuccess, submitDisabled } = this.state;
    const { _t } = this.props;
    const isDisabled = submitDisabled ? 'is--disabled' : '';
    if (saveSuccess) {
      return null;
    }
    if (fields) {
      return (
        <>
          <div className="tp-portal__inputs js--inputs">
            {fields.map((field, i) => {
              const { type } = field;
              if (type === 'hidden') {
                return null;
              }
              if (type === 'select') {
                return (
                  <Select
                    {...this.props}
                    field={field}
                    updateData={this._updateData}
                    key={i}
                  />
                );
              }
              return (
                <Text
                  {...this.props}
                  field={field}
                  updateData={this._updateData}
                  key={i}
                />
              );
            })}
          </div>
          <PageActionButton
            label={_t('save')}
            className={`input-buttons__wrap__signup`}
            isDisabled={!!isDisabled}
            onActionButtonClick={this._onSubmit}
          />
          <Typography className={'input-buttons__wrap__info-text'}>
            {_t('portal.form.required_fields')}
          </Typography>
        </>
      );
    }
    return <Loading />;
  }

  render() {
    const { _t } = this.props;
    return (
      <div className="tp-portal__form">
        {this._renderLoading()}
        <div className="tp-portal__form-content">
          <LogoHeader />
          <ServicePageName
            pageName={_t('createUserAccount')}
            className="tp-portal__header__signup"
          />
          {this._renderSuccess()}
          {this._renderError()}
          {this._renderContent()}
        </div>
      </div>
    );
  }
}

export default Signup;
