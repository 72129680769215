import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { AppContext } from '../../../contexts/AppProvider';
import { getTranslatedName } from '../_utils/TranslatedName';
import FavButton from './FavButton';

const BoxedView = (props) => {

  const { allProjects, _t } = props;
  const { selectedLanguage } = useContext(AppContext);

  return (
    <>
      {allProjects &&
      allProjects.map((field, i) => {
        const { name, url, names_translated } = field;
        return (
          <div 
            className={`boxed-item`} 
            key={i} 
            onClick={(e) => {
              e.preventDefault();
              const selection = window.getSelection();
              if (selection.type !== 'Range') {
                window.location.href = url;
              }
            }}
          >
            <FavButton 
              project={field}
              {...props}
            />
            <div className={`boxed-item__inner`} data-sel={`project-box-${i}`}>
              <div className={'boxed-item__inner__info-fields'}>
                <Tooltip
                  className={'boxed-item__inner__info-fields__tooltip'}
                  title={getTranslatedName(names_translated, name, selectedLanguage)}
                  placement="top"
                >
                  <h3 
                    className={'boxed-item__inner__info-fields__name'} 
                    data-sel={`project-box-name`}
                  >
                    {getTranslatedName(names_translated, name, selectedLanguage)}
                  </h3>
                </Tooltip>
                <div 
                  className={`boxed-item__inner__info-fields__short-name`} 
                  data-sel={`project-box-short-name`}
                >
                  {name.toUpperCase() || ''}
                </div>
              </div>
              <div className={`boxed-item__inner__footer`}>
                <a href={url} className={`boxed-item__inner__footer__link`}>
                  {_t('openProject')}
                </a>
              </div>
            </div>
          </div>
        );
      })
      }
    </>
  )
}

export default BoxedView;