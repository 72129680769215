import React from 'react';
import { retrieve } from './../../../Utils/Retrieve';
import Configs from './../../../../config/default';
import Loading from './../../../Utils/Loading';
import SetPasswordForm from '../../../General/Form/SetPasswordForm';
import { Link } from 'react-router-dom';
import { getFieldValueById } from '../../../Utils/Fields';
import PageActionButton from '../../pageActionButton';
import { Box, Typography } from '@mui/material';

class SetNewPassword extends React.Component {
  constructor(props) {
    super(props);
    const {
      routes: { auth0Domain },
    } = Configs;
    const {
      userProps: { username, token, localeMapper, state },
      _t,
    } = props;
    this.state = {
      fields: [
        {
          id: 'newPassword',
          label: _t('newPassword'),
          name: 'password',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: '',
        },
        {
          id: 'confirmPassword',
          label: _t('confirmPassword'),
          name: 'password1',
          required: true,
          type: 'password',
          classes: 'is--secure',
          errors: [],
          value: '',
        },
      ],
      showForm: true,
      token,
      username,
      localeMapper,
      pwPolicy: null,
      submitReady: false,
      serverError: '',
      isLoading: true,
      saveSuccess: false,
      continueUrl: `//${auth0Domain}/continue?token=${token}&state=${state}`,
    };
    this._updateData = this._updateData.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  componentDidMount() {
    const { pwPolicy } = this.state;
    if (!pwPolicy) {
      return this._getPwPolicy();
    }
  }

  async _getPwPolicy() {
    const {
      routes: { gum },
    } = Configs;
    const { username } = this.state;

    const { data, statusText } = await retrieve(
      '',
      `${gum.public}/pwpolicy/${username}`,
      'GET'
    );
    if (statusText === 'OK') {
      let {
        result: {
          pw_digits,
          pw_lower_and_uppercase,
          pw_min_length,
          pw_special_chars_allowed,
          pw_special_chars_count,
        },
      } = data;

      !this.cancelled &&
        this.setState({
          pwPolicy: {
            ...(!!pw_digits && { pw_digits: !!pw_digits }),
            ...(!!pw_lower_and_uppercase && {
              pw_lower_and_uppercase: !!pw_lower_and_uppercase,
            }),
            pw_min_length,
            pw_special_chars: {
              allowed: pw_special_chars_allowed,
              count: pw_special_chars_count,
            },
          },
          isLoading: false,
        });
    } else {
      !this.cancelled &&
        this.setState({
          pwPolicy: {},
          isLoading: false,
        });
    }
  }

  _updateData(data) {
    const { submitReady, fields } = data;

    this.setState({
      submitReady,
      fields,
    });
  }

  _getMappedTranslation(key) {
    const { _t } = this.props;
    const { localeMapper } = this.state;
    let transl = localeMapper[key] || [key];
    transl = transl.map((msg) => {
      return _t(msg);
    });
    return transl;
  }

  _onSubmit = async (e) => {
    e.preventDefault();
    const {
      routes: { gum },
    } = Configs;
    const { submitReady, token, fields } = this.state;
    const newPassword = getFieldValueById(fields, 'newPassword');
    let serverError = '';
    if (submitReady) {
      this.setState({ submitReady: false, isLoading: true });
      const url = `${gum.general}/a0/changePassword`;
      const requestPassword = await retrieve(token, url, 'POST', {
        newPassword,
      });
      const {
        statusText,
        data: { error },
      } = requestPassword;
      if (statusText === 'OK') {
        this.setState({
          serverError,
          saveSuccess: true,
          isLoading: false,
        });
      } else {
        const { message } = error;
        serverError = this._getMappedTranslation(message || '');
        return this.setState({
          serverError,
          showForm: message === 'password is in history',
          isLoading: false,
          submitDisabled: false,
        });
      }
    }
  };

  _renderSuccess() {
    const { saveSuccess, continueUrl } = this.state;
    const { _t } = this.props;
    const isSuccess = saveSuccess ? 'is--success' : '';
    if (saveSuccess) {
      setTimeout(() => window.location.replace(continueUrl), 3000);
      return (
        <>
          <div className={`tp-portal__messages ${isSuccess}`}>
            <Typography data-sel="success">
              {_t('portal.resetPassword.changedSuccess')}
            </Typography>
          </div>
        </>
      );
    }
    return null;
  }
  handleRedirect = () => {
    this.props.navigate('/');
  };
  _renderError() {
    const { _t } = this.props;
    const { serverError, showForm } = this.state;
    const isHidden = showForm ? 'is--hidden' : '';
    if (serverError) {
      return (
        <>
          <div className={'tp-portal__messages has--errors'}>
            {serverError.map((msg, i) => {
              return (
                <Typography data-sel={`error-${i}`} key={i}>
                  {msg}
                </Typography>
              );
            })}
          </div>
          <Box className={`input-buttons__wrap ${isHidden}`}>
            <PageActionButton
              data-sel="ok-link"
              className="input-buttons__wrap__password-reset"
              onActionButtonClick={this.handleRedirect}
              label={_t('ok')}
            />
          </Box>
        </>
      );
    }
    return null;
  }

  _renderContent() {
    const { fields, pwPolicy, saveSuccess, submitReady, showForm } = this.state;
    const { _t } = this.props;
    const isDisabled = !submitReady ? 'is--disabled' : '';
    if (saveSuccess || !showForm) {
      return null;
    }
    const compProps = {
      ...this.props,
      pwPolicy,
      fields,
      updateData: this._updateData,
    };

    return (
      <>
        <SetPasswordForm {...compProps} />
        <div className={'input-buttons__wrap'}>
          <PageActionButton
            label={_t('ok')}
            isDisabled={!!isDisabled}
            className={`input-buttons__wrap__signup`}
            onActionButtonClick={this._onSubmit}
          />
          <Typography className={'input-buttons__wrap__info-text'}>
            {_t('portal.form.required_fields')}
          </Typography>
        </div>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loading />;
    }
    return (
      <>
        {this._renderSuccess()}
        {this._renderError()}
        <div className="tp-portal__inputs js--inputs">
          {this._renderContent()}
        </div>
      </>
    );
  }
}

export default SetNewPassword;
