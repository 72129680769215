import React from 'react';
import { AppContext } from '../../contexts/AppProvider';
import Configs from '../../config/default';
import { retrieve } from '../Utils/Retrieve';
import Loading from '../Utils/Loading';
import NothingFound from '../Utils/NothingFound';
import TableView from "./_components/TableView";

class Instances extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      allInstances: [],
    };
  }
  
  componentDidMount() {
    const { allInstances } = this.state;
    if (!allInstances.length) {
      return this._getInstances();
    }
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  async _getInstances() {
    const { profile: { accessToken }} = this.context;
    const { routes: { gum } } = Configs;
    const { data } = await retrieve(accessToken, `${gum.self}/instances`, 'GET');
    if (data && data.meta && data.meta.total) {
      !this.cancelled && this.setState({
        allInstances: data.instances.sort((a, b) => {
          a.name = a.name || '';
          b.name = b.name || '';
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        }),
        totalResults: data.meta.total,
        isLoading: false
      });
    }
  }
  
  _renderPageBody() {
    const { _t } = this.props;
    const { isLoading, allInstances, totalResults } = this.state;
    if (isLoading === true) {
      return <Loading/>;
    } else if (totalResults && allInstances.length === 0) {
      return <NothingFound msg={_t('portal.noEntriesFound')}/>;
    } else if (allInstances.length === 0) {
      return <NothingFound msg={_t('portal.instances.userHasNoInstances')}/>;
    } else {
      return (
        <TableView instances={allInstances} {...this.props}/>
      )
    }
  }
  
  render() {
    const { _t } = this.props;
    return (
      <div className={'main instances'} data-sel={'instances'}>
        <div className={'page__header'}>
          <div className={'page__header__items'}>
            <div className={'page__header__items__left'}>
              <h2 className={'page__header__items__left__title'} data-sel={'instances-title'}>
                {_t('portal.instances')}
              </h2>
            </div>
          </div>
          <hr className={'page__header__separator'}/>
        </div>
        <div className={'instances__body'} data-sel={'instances-body'}>
          {this._renderPageBody()}
        </div>
      </div>
    )
  }
}

export default Instances;
