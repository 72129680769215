import React from 'react';
import { connect } from 'react-redux';
import { AppContext } from '../../../contexts/AppProvider';
import { setWarningOpen } from '../../../redux/reducers/warningStatusReducer';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import Configs from './../../../config/default';
import _ from 'lodash';

class DropDownMenuUser extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      fullName: ''
    };
  }

  componentDidMount() {
    this.setState({
      options: this._getFields()
    })
  }
  
  _getFields() {
    const { profile, profile: { cic_linked } } = this.context;
    const { _t, tpAuth: { isOrgaAdmin } } = this.props;
    const { routes: { gum: { admin: gumLink } } } = Configs;
  
    return [
      { value: 'edit_account', label: this._editUserTemplate(profile) },
      { ...(isOrgaAdmin && { value: 'gum', label: <a href={gumLink}>{_t('portal.gum')}</a> }) },
      ...(cic_linked === "1" ? [{ value: 'cic', label: _t('portal.groupPortalLink.label') }] : []),
      { value: 'logout', label: _t('portal.logout') },
    ].filter(item => item.value);
  }
  
  _editUserTemplate(profile) {
    const { _t } = this.props;
    let fullName = _.capitalize(profile.givenName) + ' ' + _.capitalize(profile.sn);
    this.setState({fullName});
    return (
      <Link to={'/account/profile'} className={'user-edit'} data-sel={'user-nav-card'}>
        <div className={'user-edit__name'} data-sel={'user-nav-name'}>{fullName}</div>
        <div className={'user-edit__email'} data-sel={'user-nav-email'}>{profile.mail}</div>
        <div className={'user-edit__button'}>
          <button className={'tpcde-button'} data-sel={'user-nav-edit-button'}>
            {_t('portal.editMyAccount')}
          </button>
        </div>
      </Link>
    )
  }
  
  onChange(selected) {
    const { value } = selected;
    const { tpAuth } = this.props;
    if (value === 'logout') {
      this.props.setWarningOpen();
      return tpAuth.logout();
    }
    if (value === 'cic') {
      window.open(Configs.urls.cic, '_blank');
    }
  }
  
  render() {
    const { fullName } = this.state;
    return (
      <div className='dropdown' data-sel={'user-nav-dropdown'}>
        <ReactSelect
          id={'user-nav'}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={e => this.onChange(e)}
          placeholder={fullName}
          isSearchable={false}
          isClearable={false}
          className={'dropdown__select'}
          classNamePrefix={'dropdown__select'}
          value={'Deutsch'}
          options={this.state.options}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setWarningOpen: (...args) => dispatch(setWarningOpen(...args))
  };
}

export default connect(null, mapDispatchToProps)(DropDownMenuUser);
