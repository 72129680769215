import React from 'react';
import ReactSelect from 'react-select';
import validateField from './../../Utils/FieldValidator';

class Select extends React.Component {
  constructor(props) {
    super(props);
    const { field } = props;
    this.state = {
      ...field,
      id: field.id || field.name,
      errors: []
    };
  }
  
  componentDidMount() {
    const { selected } = this.state;
    if (selected) {
      this.onChange(selected);
    }
  }
  
  validateValue() {
    const errors = validateField(this.state);
    const { updateData } = this.props;
    
    const { options, value } = this.state;
    const validSelection = options.find((option) => {
      return option.value === value;
    });
    if (validSelection) {
      this.setState({ errors }, () => {
        updateData(this.state);
      });
    } else {
      this.setState({ value: '' }, () => {
        updateData(this.state);
      });
    }
  }
  
  onChange(selected) {
    if (!selected) {
      return this.setState({ value: '', selected }, () => {
        this.validateValue();
      });
    }
    const { value } = selected;
    this.setState({ value, selected }, () => {
      this.validateValue();
    });
  }
  
  _renderErrors() {
    const { _t } = this.props;
    const { errors } = this.state;
    if (errors.length) {
      return (
        <ul className={'input-field__wrap__errors__list'}>
          {errors.map((error, i) => {
            return (
              <li className={'input-field__wrap__errors__list__item'} key={i}>
                {_t(`portal.form.${error.method}`, error.param)}
              </li>
            );
          })}
        </ul>
      )
    }
    return null;
  }
  
  render() {
    const { id, label, name, classes, selected, errors, options, isSearchable, isClearable, placeholder, required } = this.state;
    const isRequired = required ? 'is--required' : '';
    const hasErrors = errors.length ? 'has--errors' : '';
    const { _t } = this.props;
    return (
      <div className={`input-field__wrap ${classes || ''} ${isRequired} ${hasErrors}`}>
        <label className={'input-field__wrap__label'} htmlFor={id}>
          {_t(label)}
        </label>
        <ReactSelect
          id={id}
          name={name}
          onChange={e => this.onChange(e)}
          placeholder={placeholder || '...'}
          isSearchable={isSearchable}
          isClearable={isClearable}
          className={'input-field__wrap__select'}
          classNamePrefix={'input-field__wrap__select'}
          value={selected || null}
          options={options}
        />
        <div className={'input-field__wrap__errors'}>
          {this._renderErrors()}
        </div>
      </div>
    );
  }
}

export default Select;
