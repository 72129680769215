import React from 'react';
import { connect } from 'react-redux';
import { AppContext } from '../../contexts/AppProvider';
import Configs from '../../config/default';
import { retrieve } from '../Utils/Retrieve';
import Loading from '../Utils/Loading';
import BoxedView from "./_components/BoxedView";
import TableView from "./_components/TableView";
import NothingFound from '../Utils/NothingFound';
import SortSelect from "./_components/SortSelect";
import SearchField from "./_components/SearchField";
import Paginator from "./_components/Paginator";
import LayoutButtons from './_components/LayoutButtons';
import { setBoxLayout } from '../../redux/reducers/layoutReducer';
import { removeBoxLayout } from '../../redux/reducers/layoutReducer';
import QueryString from 'query-string';
import _ from 'lodash';
import WithNavAndLocation from '../WithNavAndLocation';

class Projects extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    const queries = QueryString.parse(props.location.search);
   
    this.state = {
      boxLayout: true,
      isLoading: true,
      allProjects: [],
      totalResults: 0,
      pageSize: 20,
      defaultQueries: {
        only_active_users: 1,
        only_active_projects: 1,
      },
      queries
    };
    this.abortController = null;
  }
  
  componentDidMount() {
    const { allProjects } = this.state;
    
    if (!allProjects.length) {
      return this._getProjects();
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const newQueries = QueryString.parse(this.props.location.search);
    if (!_.isEqual(prevState.queries, newQueries)) {
      return this._getProjects();
    }
  }
  
  static getDerivedStateFromProps(props, state) {
    
    const newQueries = QueryString.parse(props.location.search);
    if (!_.isEqual(state.queries, newQueries)) {
      return {
        isLoading: true,
        queries: newQueries
      }
    }
    return state;
  }
  
  componentWillUnmount() {
    this.cancelled = true;
  }
  
  async _getProjects() {
    const { pageSize, queries, defaultQueries } = this.state;
    
    const pageNr = queries.page || 1;
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    const { signal } = this.abortController;
    const { selectedLanguage, profile: { accessToken } } = this.context;
    const { routes: { gum } } = Configs;
    const { data } = await retrieve(accessToken, `${gum.general}/global/projects`, 'GET', {
      ...defaultQueries,
      page_size: pageSize,
      query_name: queries?.q || '',
      page_offset: (pageNr - 1) * pageSize,
      sort_by: queries.sortby || 'favorite',
      desc: queries.desc || 0,
      lang: selectedLanguage
    }, null, signal);
    // if data == undefined, this means that the last fetch has been canceled.
    if (data && data.meta && data.meta.total) {
      !this.cancelled && this.setState({
        allProjects: data.projects,
        totalResults: data.meta.total,
        isLoading: false
      });
    } else {
      this.setState({
        allProjects: [],
        isLoading: false
      });
    }
  }
  
  _boxLayout = () => {
    this.props.setBoxLayout();
  };
  
  _listLayout = () => {
    this.props.removeBoxLayout();
  };
  
  _renderProjects() {
    const { allProjects } = this.state;
    const { layout } = this.props;
    return (
      !layout ?
        <TableView 
          allProjects={allProjects} 
          {...this.props}
        /> 
        :
        <BoxedView 
          allProjects={allProjects} 
          {...this.props}
        />
    )
  }
  
  _renderPaginator() {
    const {
      totalResults,
      pageSize,
      queries
    } = this.state;
    const { _t } = this.props;
    const options = { totalResults, pageSize };
    if (totalResults > pageSize) {
      return (
        <div className={'projects__body__pagination'}>
          <Paginator options={options} queries={queries} _t={_t} />
        </div>
      )
    }
    return null;
  }
  
  _renderPageBody() {
    const { _t } = this.props;
    const { isLoading, allProjects, totalResults } = this.state;
   
    if (isLoading === true) {
      return <Loading/>;
    } else if (totalResults && allProjects.length === 0) {
      // if totalResult > 0 but no projects means we are out of range (wrong page number) or user search has no entries
      return <NothingFound msg={_t('portal.noEntriesFound')}/>;
    } else if (allProjects.length === 0) {
      // user has no projects at all
      return <NothingFound msg={_t('portal.projects.userHasNoProjects')}/>;
    }
    return (
      <>
        {this._renderProjects()}
        {this._renderPaginator()}
      </>
    )
  }
  
  render() {
    const { layout, _t } = this.props;
    const { queries } = this.state;
    return (
      <div className={'main projects'} data-sel={'projects'}>
        <div className={'page__header'}>
          <div className={'page__header__items'}>
            <div className={'page__header__items__left'}>
              <h2 
                className={'page__header__items__left__title'}
                data-sel={'projects-title'}>{_t('portal.projects')}
              </h2>
            </div>
            <div className={'page__header__items__right'}>
              <div className={'page__header__items__right__buttons-and-select'}>
                <LayoutButtons
                  boxLayout={this._boxLayout}
                  listLayout={this._listLayout}
                  layout={layout}
                  {...this.props}
                />
                <SortSelect queries={queries} {...this.props}/>
              </div>
              <SearchField queries={queries} {...this.props}/>
            </div>
          </div>
          <hr className={'page__header__separator'}/>
        </div>
        <div className={'projects__body'} data-sel={'projects-body'}>
          {this._renderPageBody()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layout.boxLayout
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBoxLayout: () => dispatch(setBoxLayout()),
    removeBoxLayout: () => dispatch(removeBoxLayout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavAndLocation(Projects));