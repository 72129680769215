import React from 'react';

const Image = (props) => {

  const { baseUrl } = props; 
  const { large } = props.image?.sizes;

  return (
    <div 
      className='news-detail__body__image'
      data-sel={'news-detail-body-image'}
    >
      <img className={'news-detail__body__image__img'} src={baseUrl+large}/>
    </div>
  );
}

export default Image;