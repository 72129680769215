import React from 'react';
import _ from "lodash";
import { AppContext } from '../../../contexts/AppProvider';

class Profile extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      fields: null
    };
  }

  componentDidMount() {
    this.setState({fields: this._getFields()});
  }
  
  _getFields() {
    const { _t } = this.props;
    const { profile, languages } = this.context;
    return [
      {
        key: 'givenName',
        label: _t('name'),
        value: profile.givenName,
        editable: true
      },
      {
        key: 'sn',
        label: _t('lastName'),
        value: profile.sn,
        editable: true
      },
      {
        key: 'gender',
        label: _t('gender'),
        value: profile.gender,
        editable: true
      },
      {
        key: 'portalLanguage',
        label: _t('communicationLanguage'),
        value: languages[profile.portalLanguage] || profile.portalLanguage,
        editable: true
      },
      {
        key: 'title',
        label: _t('title'),
        value: profile.title,
        editable: true
      },
      {
        key: 'telephoneNumber',
        label: _t('telephoneNumber'),
        value: profile.telephoneNumber,
        editable: true
      }
    ];
  }
  
  render() {
    const { _t } = this.props;
    const { fields } = this.state;
    return (
      <>
        <div className={'profile__intro'}>
          <div className={'profile__intro__title'}>
            <h2 data-sel={'account-profile-title'}>{_t('portal.account.profile')}</h2>
          </div>
          <div className={'profile__intro__text'}>
            <h3 data-sel={'account-profile-intro'}>{_t('portal.account.profile.intro')}</h3>
          </div>
        </div>
        <div className={'profile__items'}>
          {fields && fields.map((field, i) => {
            const { label, value, editable } = field;
            return (
              <div className={`profile__items__item ${editable ? 'with--arrow' : ''}`} key={i}>
                <div className={'profile__items__item__key'} title={label}>{label}</div>
                <div className={`profile__items__item__value ${editable ? '' : 'is--disabled'}`}>{value}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default Profile;
