const getErrorMapper = (type) => {
  const locales = {
    'account-activation': {
      'invalid token': ['portal.accountActivation.invalidToken'],
      'token expired': ['portal.accountActivation.invalidToken'],
      'user is federated': ['portal.resetPassword.userIsFederated'],
      'account deactivated': ['portal.accountDeactivated'],
      'no token': ['portal.accountActivation.invalidToken']
    },
    'forgot-password': {
      'too many requests for this user.': ['portal.tooManyRequests'],
      'invalid token': ['portal.resetPassword.invalidToken'],
      'token expired': ['portal.resetPassword.invalidToken'],
      'user is federated': [
        'portal.resetPassword.userIsFederated',
        'portal.resetPassword.userIsFederated.paragraph.1'
      ],
      'account deactivated': ['portal.accountDeactivated']
    },
  };
  return locales[type] ? locales[type] : {};
};

export { getErrorMapper };