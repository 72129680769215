import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

class TableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitles: [
        { title: 'portal.instances.name', className: 'table__head__item__cell__name' },
        { title: 'portal.instances.description', className: 'table__head__item__cell__description' },
        { title: '' },
      ]
    }
  }
  
  render() {
    const { _t, instances } = this.props;
    const headerTitles = Object.entries(this.state.headerTitles);
    const tableContainerStyles = {
      overflowX: 'auto'
    };
    const tableStyles = {
      minWidth: 650
    }
    
    return (
      <TableContainer className={`table-container`} sx={tableContainerStyles}>
        <Table className={`table`} sx={tableStyles} aria-label="simple table">
          <TableHead className={`table__head`}>
            <TableRow className={`table__head__item`}>
              {headerTitles.map((title, i) =>
                <TableCell className={title[1].className} key={i}>
                  {_t(`${title[1].title}`)}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={`table__body`}>
            {instances.map((instance, i) => (
              <TableRow
                className={`table__body__item`}
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-sel={`table-body-item-row-${i}`}
              >
                <TableCell
                  className={`table__body__item__name`}
                  scope="row"
                  data-sel={`table-body-item-name`}
                >
                  <Tooltip
                    title={instance.name}
                    placement="top"
                  >
                    <a href={instance.url} target={'_blank'} className={`table__body__item__name__text`}>
                      {instance.name}
                    </a>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={`table__body__item__description`}
                  scope="row"
                  data-sel={`table-body-item-description`}
                >
                  <a href={instance.url} target={'_blank'}>
                    {instance.desc ? instance.desc.toUpperCase() : ''}
                  </a>
                </TableCell>
                <TableCell
                  className={`table__body__item__link`}
                  scope="row"
                  align="right"
                  data-sel={`table-body-item-link`}
                >
                  <a href={instance.url} target={'_blank'} className={`table__body__item__link__element`}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default TableView;