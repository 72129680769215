import { createAction, createReducer } from '@reduxjs/toolkit';
import Configs from '../../config/default';

const { defaultLocale, availableLanguages } = Configs;

let locale = navigator.languages && navigator.languages[0] ||
  navigator.language || navigator.userLanguage;
locale = locale.replace('-', '_');
locale = locale.length === 2 ? `${locale}_${locale.toUpperCase()}` : locale;
locale = availableLanguages[locale] ? locale : defaultLocale;

export const saveLocale = createAction('locale/saveLocale');

const initialState = { locale };

const localeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(saveLocale, (state, action) => {
      state.locale = action.payload 
    })
});

export default localeReducer;