import React from 'react';

const Username = ({ userInfo, keyIndex }) => {
 
 const { editable, label, value } = userInfo[0];

  return (
    <div className={`profile__items__item ${editable ? 'with--arrow' : ''}`} key={keyIndex}>
      <div className={'profile__items__item__key'} title={label}>{label}</div>
      <div className={`profile__items__item__value ${editable ? '' : 'is--disabled'}`}>
        {value}
      </div>
    </div>
  );
}

export default Username;